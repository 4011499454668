import * as types from "./actionTypes";

const initialState = {
  companiesList: undefined,
  countrysidesList: undefined,
  usersList: undefined,
  devicesList: undefined,
  organizationsList: undefined,
  createOrUpdateResponse: undefined,
  noOrganizationCompaniesList: undefined,
  provinces: [],
  localities: [],
  cities: [],
  loading: false,
  error: {
    message: "",
  },
};

const OrganizationsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.CREATE_ORGANIZATION:
    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE:
    case types.CLEAN_ORGANIZATION_DATA:
    case types.DELETE_ORGANIZATION:
    case types.GET_COMPANIES_DATA:
    case types.GET_CITY:
    case types.GET_LOCALITY:
    case types.GET_PROVINCE:
    case types.GET_ORGANIZATION_DATA:
    case types.GET_ORGANIZATIONS_LIST_DATA:
    case types.GET_ORGANIZATION_DEVICES_DATA:
      state = { ...state, loading: true };
      break;

    case types.GET_ORGANIZATIONS_LIST_DATA_SUCCESS:
      state = { ...state, organizationsList: actionPayload, loading: false };
      break;

    case types.GET_ORGANIZATION_DATA_SUCCESS:
      const countrysidesLocalList: any = [];
      const companies: any = [];
      let users: any = [];

      if (actionPayload && actionPayload.length > 0) {
        actionPayload.forEach((value: any) => {
          users = value.users;
          value.companies.forEach((val: any) => {
            const { countrysides, address, ...rest } = val;
            const addressValues = {
              locality_id: address.locality_id,
              municipality_id: address.municipality_id,
              street_name: address.street_name,
              street_number: address.street_number,
              floor: address.floor,
              apartment: address.apartment,
              zip_code: address.zip_code,
            };
            companies.push({ ...rest, ...addressValues });
            countrysides.forEach((value: any) => {
              countrysidesLocalList.push(value);
            });
          });
        });
      }

      state = {
        ...state,
        usersList: users,
        countrysidesList: countrysidesLocalList,
        companiesList: companies,
        loading: false,
      };
      break;
    case types.GET_COMPANIES_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.GET_COMPANIES_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        noOrganizationCompaniesList: actionPayload,
      };
      break;

    case types.GET_CITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        cities: actionPayload.response,
        localities: actionPayload.cleanData ? [] : state.localities,
      };
      break;

    case types.GET_PROVINCE_SUCCESS:
      state = {
        ...state,
        loading: false,
        provinces: actionPayload.response,
        localities: actionPayload.cleanData ? [] : state.localities,
      };
      break;

    case types.GET_LOCALITY_SUCCESS:
      state = { ...state, loading: false, localities: actionPayload };
      break;

    case types.CREATE_ORGANIZATION_SUCCESS:
      state = {
        ...state,
        createOrUpdateResponse: actionPayload,
        loading: false,
      };
      break;

    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS:
      state = {
        ...state,
        createOrUpdateResponse: undefined,
        loading: false,
      };
      break;

    case types.UPDATE_ORGANIZATION:
      state = {
        ...state,
        createOrUpdateResponse: actionPayload,
        loading: true,
      };
      break;

    case types.UPDATE_ORGANIZATION_SUCCESS:
      state = { ...state, loading: false };
      break;

    case types.DELETE_ORGANIZATION_SUCCESS:
      state = { ...state, loading: false };
      break;

    case types.CLEAN_ORGANIZATION_DATA_SUCCESS:
      state = {
        ...state,
        companiesList: undefined,
        countrysidesList: undefined,
        usersList: undefined,
        devicesList: undefined,
        loading: false,
      };
      break;

    case types.GET_ORGANIZATION_DEVICES_DATA_SUCCESS:
      state = {
        ...state,
        devicesList: actionPayload,
      };
      break;

    case types.CREATE_ORGANIZATION_FAIL:
    case types.CLEAN_ORGANIZATION_DATA_FAIL:
    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL:
    case types.DELETE_ORGANIZATION_FAIL:
    case types.GET_CITY_FAIL:
    case types.GET_LOCALITY_FAIL:
    case types.GET_PROVINCE_FAIL:
    case types.GET_ORGANIZATION_DATA_FAIL:
    case types.GET_ORGANIZATIONS_LIST_DATA_FAIL:
    case types.UPDATE_ORGANIZATION_FAIL:
    case types.GET_ORGANIZATION_DEVICES_DATA_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
  }

  return state;
};

export default OrganizationsReducer;
