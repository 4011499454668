export const renderHandleColor = (source: string) => {
  let color = "#546C91";
  switch (source) {
    case "$$-0":
      color = "#FD7E14";
      break;
    case "$$-1":
      color = "#F4C525";
      break;
    case "$$-2":
      color = "#6BAA00";
      break;
    case "$$-3":
      color = "#6BAA00";
      break;
    case "$$-4":
      color = "#F4C525";
      break;
    case "$$-5":
      color = "#FD7E14";
      break;
  }

  return color;
};

export const renderTrackerColor = (source: string) => {
  let color = "#546C91";
  switch (source) {
    case "$$-0":
      color = "#FD7E14";
      break;
    case "$$-1":
      color = "#F4C525";
      break;
    case "$$-2":
      color = "#6BAA00";
      break;
    case "$$-3":
      color = "#F4C525";
      break;
    case "$$-4":
      color = "#FD7E14";
      break;
    case "$$-5":
      color = "#FD7E14";
      break;
  }

  return color;
};
