import React, { CSSProperties } from "react";

interface CropIconComponentProps {
  crop: string;
  style?: CSSProperties | undefined;
}

const CropIconComponent = (props: CropIconComponentProps) => {
  return <></>;
};

export default CropIconComponent;
