import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  alphanumericRegex,
  alphanumericWithSpecialCharsRegex,
  cuitRegex,
  emailRegex,
  numericRegex,
} from "../../../../shared/regexPatterns";
import { ReduxType } from "../../../../shared/types";
import {
  getCitiesListDataAction,
  getLocalitiesListDataAction,
} from "../../../../store/organizations/actions";
import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";

export interface FormGridProps {
  onSubmit: (e: any) => void;
  onChange: ({ value, name }: { value: any; name: string }) => void;
  editionMode: boolean;
  formValues: any;
}

const FormGridComponent = (props: FormGridProps) => {
  const { onChange, formValues, editionMode } = props;

  const dispatch = useDispatch();

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  React.useEffect(() => {
    getCityAndLocalityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCityAndLocalityData = async () => {
    if (!organizationsData.cities || organizationsData.cities.length === 0) {
      await dispatch(getLocalitiesListDataAction(null));
      await dispatch(getCitiesListDataAction(null, false));
    }
  };

  const getOnChange = async (name: string, id: any) => {
    switch (name) {
      case "municipality_id": //Cities
        onChange && onChange({ name: "municipality_id", value: id });
        break;
      case "locality_id":
        onChange && onChange({ name: "locality_id", value: id });
        break;
      default:
        break;
    }
  };

  const onDomicilioChange = ({ value, name }: { value: any; name: string }) => {
    getOnChange(name, value.target.value);
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item xs={12}>
        <CustomTextField
          id="tax_id"
          name="tax_id"
          variant="filled"
          style={{ width: "100%" }}
          defaultValue={formValues.tax_id}
          onChange={onChange}
          required
          label="CUIT sin guiones ni espacios"
          pattern={cuitRegex}
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          id="name"
          variant="filled"
          name="name"
          pattern={alphanumericWithSpecialCharsRegex}
          required
          style={{ width: "100%" }}
          defaultValue={formValues.name}
          onChange={onChange}
          label="Nombre"
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          id="legal_name"
          name="legal_name"
          variant="filled"
          pattern={alphanumericWithSpecialCharsRegex}
          style={{ width: "100%" }}
          required
          defaultValue={formValues.legal_name}
          onChange={onChange}
          label="Nombre Legal"
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextField
          id="phone"
          label="Telefono"
          name="phone"
          variant="filled"
          style={{ width: "100%" }}
          defaultValue={formValues.phone}
          pattern={numericRegex}
          onChange={onChange}
          required
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomTextField
          id="email"
          name="email"
          variant="filled"
          style={{ width: "100%" }}
          defaultValue={formValues.email}
          onChange={onChange}
          required
          label="Email"
          pattern={emailRegex}
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12} lg={8}>
        <CustomTextField
          id="street_name"
          name="street_name"
          variant="filled"
          style={{ width: "100%" }}
          defaultValue={formValues.street_name}
          pattern={alphanumericRegex}
          required
          onChange={onChange}
          label="Calle"
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomTextField
          id="street_number"
          name="street_number"
          variant="filled"
          style={{ width: "100%" }}
          defaultValue={formValues.street_number}
          onChange={(e) => onChange({ value: parseInt(e.value), name: e.name })}
          pattern={numericRegex}
          required
          label="Numero"
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomTextField
          id="floor"
          name="floor"
          variant="filled"
          style={{ width: "100%" }}
          defaultValue={formValues.floor}
          onChange={onChange}
          pattern={numericRegex}
          label="Piso"
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomTextField
          id="apartment"
          name="apartment"
          variant="filled"
          style={{ width: "100%" }}
          defaultValue={formValues.apartment}
          onChange={onChange}
          label="Departamento"
          disabled={!editionMode}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomTextField
          required
          disabled={!editionMode}
          id="zip_code"
          variant="filled"
          name="zip_code"
          style={{ width: "100%" }}
          defaultValue={formValues.zip_code}
          onChange={(e) => onChange({ value: parseInt(e.value), name: e.name })}
          label="Codigo postal"
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="ciudad-select-label">Ciudad *</InputLabel>
          <StyledSelect
            labelId="ciudad-select-label"
            id="municipality_id"
            name="municipality_id"
            defaultValue={
              formValues.municipality_id ? formValues.municipality_id : ""
            }
            disabled={!editionMode}
            required
            onChange={(e) =>
              onDomicilioChange({ value: e, name: "municipality_id" })
            }
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }}
          >
            {organizationsData.cities &&
              organizationsData.cities.length > 0 &&
              organizationsData.cities.map((value: any, index: number) => {
                return (
                  <MenuItem value={value.id} key={`select-city-${index}`}>
                    {value.name}
                  </MenuItem>
                );
              })}
          </StyledSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormControl variant="filled" fullWidth>
          <InputLabel id="localidad-select-label">Localidad *</InputLabel>
          <StyledSelect
            labelId="localidad-select-label"
            id="locality_id"
            name="locality_id"
            required
            defaultValue={formValues.locality_id ? formValues.locality_id : ""}
            disabled={!editionMode}
            onChange={(e) =>
              onDomicilioChange({ value: e, name: "locality_id" })
            }
          >
            {organizationsData.localities &&
              organizationsData.localities.length > 0 &&
              organizationsData.localities
                .filter((l) => l.municipality_id === formValues.municipality_id)
                .map((value: any, index: number) => {
                  return (
                    <MenuItem key={`localities-${index}`} value={value.id}>
                      {value.name}
                    </MenuItem>
                  );
                })}
          </StyledSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormControlLabel
          checked={!!formValues.blockchain_enabled}
          control={<Checkbox />}
          label="Registro en blockchain habilitado"
          labelPlacement="start"
          disabled={!editionMode}
          onChange={(e: any) =>
            onChange &&
            onChange({
              name: "blockchain_enabled",
              value: e.target.checked,
            })
          }
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <FormControlLabel
          checked={!!formValues.prosegur_monitoring}
          control={<Checkbox />}
          label="Monitoreo con prosegur habilitado"
          disabled={!editionMode}
          labelPlacement="start"
          onChange={(e: any) =>
            onChange &&
            onChange({ name: "prosegur_monitoring", value: e.target.checked })
          }
        />
      </Grid>
    </Grid>
  );
};

export default FormGridComponent;
