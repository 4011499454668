import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { ReduxType } from "../../../shared/types";
import {
  getPermissionsListAction,
  getRolesListAction,
} from "../../../store/roles/actions";
import { LayoutGridBoxComponent } from "../layoutGridBox";
import { UserFormGridComponent } from "./userFormGrid";
import { ToastComponent } from "../../toast";
import { Button, Divider, Grid, Modal } from "@mui/material";
import { apiGet, apiPatch, apiPost, apiPut } from "../../../shared/ApiService";
import { API_URL_AUTH, API_WOZALABS_URL } from "../../../config/general-config";
import { Endpoints } from "../../../config/endpoints";
import {
  alphanumericRegex,
  dniRegex,
  emailRegex,
  numericRegex,
} from "../../../shared/regexPatterns";
import { newUserFormValues } from "../../../shared/FormValues";
import { validateForm } from "../../../shared/validations";
import { getOrganizationsListDataAction } from "../../../store/organizations/actions";
import { findIndex } from "../../../shared/helper";

const style = {
  position: "absolute" as "absolute",
  top: "58%",
  left: "50%",
  transform: "translate(-50%, -60%)",
  width: 800,
  backgroundColor: "#FFFFFF",
  maxHeight: "80%",
  display: "flex",
  flexDirection: "column",
};

const validatorValues = [
  { name: "organization_id", nameToShow: "organizacion", required: false },
  {
    name: "fullname",
    nameToShow: "nombre y apellido",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "phone",
    nameToShow: "teléfono",
    pattern: numericRegex,
    required: false,
    regexMessage: "solo acepta valores numericos",
  },
  {
    name: "email",
    pattern: emailRegex,
    required: true,
    regexMessage: "solo acepta formato de email. Ej: example@gmail.com",
  },
  { name: "role_id", nameToShow: "rol", required: true },
  {
    name: "national_id",
    nameToShow: "DNI",
    pattern: dniRegex,
    required: true,
    regexMessage:
      "solo acepta formato de dni sin espacios ni puntos. Ej: 27845745",
  },
  { name: "active", required: false },
];

const NewUserComponent = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<any>();
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const dispatch = useDispatch();

  const { organizationsData, rolesData } = useSelector((state: ReduxType) => {
    return state;
  });

  const {
    createMode,
    userToEditOrView,
    editionMode,
    handleClose,
    open,
    setEditionMode,
  } = props;

  useEffect(() => {
    if (!createMode && userToEditOrView) {
      initialUpdate(userToEditOrView, false);
    }
    if (createMode && editionMode) {
      initialUpdate(newUserFormValues, false);
    }
  }, [createMode, editionMode, userToEditOrView]);

  useEffect(() => {
    if (!rolesData.rolesList) {
      getAllRoleData();
    }
    if (!rolesData.permissionsList) {
      getPermissions();
    }
    if (!organizationsData.organizationsList) {
      getAllOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareToastData = async (createMode: any, dni: number) => {
    let message;
    if (createMode) {
      message = `El Usuario con dni ${dni} fue creado de forma correcta.`;
    } else {
      message = `El Usuario con dni ${dni} fue modificado de forma correcta.`;
    }
    handleClose(message);
  };

  const getPermissions = async () => {
    await dispatch(getPermissionsListAction());
  };

  const getAllRoleData = async () => {
    await dispatch(getRolesListAction());
  };

  const getAllOrganizations = async () => {
    await dispatch(getOrganizationsListDataAction());
  };

  const onChange = ({ value, name }: any) => {
    setFormValues({ ...formValues, [name]: value });
  };
  const initialUpdate = async (userToEditOrView: any, loading: boolean) => {
    await setFormValues({
      id: userToEditOrView.id,
      organization_id: userToEditOrView.organization_id,
      fullname: userToEditOrView.fullname,
      phone: userToEditOrView.phone,
      email: userToEditOrView.email,
      active: userToEditOrView.active,
      role_id: userToEditOrView.role_id,
      national_id: userToEditOrView.national_id,
      user_permissions: userToEditOrView.user_permissions,
    });
    await setLoading(loading);
  };

  const setUserPermissions = async (permissions: any[], user_id: number) => {
    const body = permissions.map(({ id }) => ({ permission_id: id, user_id }));
    await apiPost({
      url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.USERS.PERMISSIONS_POST}`,
      body,
    });
  };

  const onSubmit = async (e: any) => {
    try {
      if (!validateForm(formValues, validatorValues)) {
        throw new Error(
          "Complete todos los datos obligatorios antes de continuar."
        );
      }

      formValues.username = formValues.email;
      formValues.organization_id =
        formValues.organization_id === "" ? null : formValues.organization_id;

      formValues.role_id =
        formValues.role_id === "" ? null : formValues.role_id;

      let { user_permissions, ...rest } = formValues;

      let bodyContent: any = {
        role: "iof_superadmin",
        email: rest.email,
        password: "123123",
        email_confirm: true,
        user_metadata: {
          role_id: formValues.role_id,
          fullname: rest.fullname,
          username: rest.email,
          organization_id: formValues.organization_id,
          national_id: formValues.national_id,
          phone: formValues.phone,
        },
      };

      if (createMode) {
        const user = await apiPost({
          url: `${API_URL_AUTH}/${Endpoints.AUTH.SIGNUP}`,
          body: bodyContent,
        });
        if (user) {
          const index = findIndex(
            rolesData.rolesList,
            "id",
            formValues.role_id
          );
          if (index !== -1) {
            const newUser = await apiGet({
              url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.USERS.GET_BY_EMAIL}${user.email}`,
            });
            const uid =
              (userToEditOrView && userToEditOrView.id) ||
              (newUser && newUser[0] && newUser[0].id);
            await setUserPermissions(
              rolesData.rolesList[index].permissions,
              uid
            );
          }
        }
      } else {
        bodyContent["phone"] = formValues.phone;
        await apiPut({
          url: `${API_URL_AUTH}/${Endpoints.AUTH.SIGNUP}/${userToEditOrView.auth_id}`,
          body: bodyContent,
        });
      }
      prepareToastData(createMode, formValues.national_id);
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const handlePermissionDelete = async (record: any) => {
    try {
      if (!record) return;
      const isUserPermission = formValues.user_permissions.find(
        (val: any) => val.id === record.id
      );
      setFormValues({
        ...formValues,
        user_permissions: isUserPermission
          ? formValues.user_permissions.map((val: any) =>
              val.id === record.id ? { ...val, active: !val.active } : val
            )
          : [...formValues.user_permissions, record],
      });
      if (isUserPermission) {
        await apiPatch({
          body: {
            active: !isUserPermission.active,
          },
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.USERS.PERMISSIONS_PATCH}${record.id}`,
        });
      } else {
        await apiPost({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.USERS.PERMISSIONS_POST}`,
          body: { permission_id: record.id, user_id: formValues.id },
        });
      }
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const tabName = () => {
    if (createMode) {
      return "Nuevo usuario";
    }
    return userToEditOrView && userToEditOrView.fullname;
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12} md={4}>
              <span className={`modal-main-title fw-5`}>{tabName()}</span>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <LayoutGridBoxComponent>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={5}>
                        <Button
                          className="login-button text-button"
                          style={{ width: 130 }}
                          variant="text"
                          color="info"
                          onClick={() => handleClose("cancel")}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className="login-button ligth-green "
                          style={{ margin: "auto", width: 130 }}
                          variant="contained"
                          onClick={(event) => {
                            if (editionMode) {
                              onSubmit(event);
                            } else {
                              setEditionMode(true);
                            }
                          }}
                        >
                          {editionMode ? "Guardar" : "Editar"}
                        </Button>
                      </Grid>
                    </Grid>
                  </LayoutGridBoxComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
            style={{ overflowY: "auto" }}
          >
            <Grid item xs={12}>
              {!loading && formValues && (
                <UserFormGridComponent
                  organizations={organizationsData.organizationsList}
                  permissionsList={rolesData.permissionsList}
                  rolesList={rolesData.rolesList}
                  handlePermissionDelete={handlePermissionDelete}
                  formValues={formValues}
                  createMode={createMode}
                  onChange={onChange}
                  editionMode={editionMode}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default NewUserComponent;
