import { takeLatest, put, call } from "redux-saga/effects";

import {
  CLEAN_CREATE_OR_UPDATE_RESPONSE,
  CLEAN_USER,
  DELETE_USER,
  GET_USERS_LIST,
  LOGIN_USER,
  RESET_USER,
  VERIFY_USER_ACTION,
} from "./actionTypes";

import {
  cleanCreateOrUpdateResponseFail,
  cleanCreateOrUpdateResponseSuccess,
  cleanStateActionFail,
  cleanStateActionSuccess,
  deleteUserFail,
  deleteUserSuccess,
  getUsersListFail,
  getUsersListSuccess,
  loginUserFail,
  loginUserSuccess,
  verifyUserFail,
  verifyUserSuccess,
} from "./actions";

import { AuthenticateReponse } from "../../core/auth/Type";

import {
  loginWithUsernameAndPassword,
  verifyUserData,
} from "../../core/auth/AuthService";
import { ApiResponse } from "../../shared/types";
import { apiDelete, apiGet, apiPut } from "../../shared/ApiService";
import { Endpoints } from "../../config/endpoints";
import { API_URL_AUTH, API_WOZALABS_URL } from "../../config/general-config";

function* onLoginUser(payload: any) {
  try {
    const { username, password } = payload;
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(loginWithUsernameAndPassword(username, password));
    });

    yield put(loginUserSuccess(response));
  } catch (error: any) {
    yield put(loginUserFail(error.message));
  }
}

function* onVerifyUser(payload: any) {
  try {
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(verifyUserData(payload.payload));
    });

    yield put(verifyUserSuccess(response));
  } catch (error: any) {
    yield put(verifyUserFail(error.message));
  }
}

function* onRestUser(payload: any) {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiPut({
          url: `${API_URL_AUTH}/${Endpoints.AUTH.USERS.PUT}`,
          body: payload.payload,
        })
      );
    });

    yield put(verifyUserSuccess(response));
  } catch (error: any) {
    yield put(verifyUserFail(error.message));
  }
}

function* onGetUsersList() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.USERS.GET}`,
        })
      );
    });
    yield put(getUsersListSuccess(response));
  } catch (error) {
    yield put(getUsersListFail(error));
  }
}

function* onCleanUserState() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanStateActionSuccess());
  } catch (error: any) {
    yield put(cleanStateActionFail(error.response));
  }
}

function* onDeleteUser(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiDelete({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.USERS.PATCH}${action.userId}`,
        })
      );
    });
    yield put(deleteUserSuccess(response));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* onCleanOrUpdateResponseData() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanCreateOrUpdateResponseSuccess());
  } catch (error) {
    yield put(cleanCreateOrUpdateResponseFail(error));
  }
}

function* UserSaga() {
  yield takeLatest(LOGIN_USER, onLoginUser);
  yield takeLatest(VERIFY_USER_ACTION, onVerifyUser);
  yield takeLatest(RESET_USER, onRestUser);
  yield takeLatest(CLEAN_USER, onCleanUserState);
  yield takeLatest(
    CLEAN_CREATE_OR_UPDATE_RESPONSE,
    onCleanOrUpdateResponseData
  );
  yield takeLatest(GET_USERS_LIST, onGetUsersList);
  yield takeLatest(DELETE_USER, onDeleteUser);
}

export default UserSaga;
