import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL, API_WOZALABS_URL } from "../../config/general-config";
import { apiGet, apiPatch, apiPost, apiPut } from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  createDeviceFail,
  createDeviceSuccess,
  findDeviceNetworksFail,
  findDeviceNetworksSuccess,
  findDeviceStatesFail,
  findDeviceStatesSuccess,
  findDeviceTypesFail,
  findDeviceTypesSuccess,
  getDevicesListDataFail,
  getDevicesListDataSuccess,
  getDeviceUnlinkListDataFail,
  getDeviceUnlinkListDataSuccess,
  linkOrUnlinkOrganizationFail,
  linkOrUnlinkOrganizationSuccess,
  updateDeviceFail,
  updateDeviceSuccess,
} from "./actions";
import {
  CREATE_DEVICE_ACTION,
  FIND_DEVICE_NETWORKS,
  FIND_DEVICE_STATES,
  FIND_DEVICE_TYPES,
  GET_DEVICES_LIST_DATA,
  GET_DEVICES_UNLINK_LIST_DATA,
  LINK_OR_UNLINK_ORGANIZATION,
  UPDATE_DEVICE,
} from "./actionTypes";

function* onGetDevicesListData() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({ url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.GET}` })
      );
    });
    yield put(getDevicesListDataSuccess(response));
  } catch (error) {
    yield put(getDevicesListDataFail(error));
  }
}

function* onGetDeviceUnlinkListData() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.GET_UNLINK_LIST}`,
        })
      );
    });
    yield put(getDeviceUnlinkListDataSuccess(response));
  } catch (error) {
    yield put(getDeviceUnlinkListDataFail(error));
  }
}

function* onLinkOrUnlinkOrganization(action: any) {
  try {
    const { organization_id, devices_id } = action.payload;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPatch({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.LINK_OR_UNLINK}(${devices_id})`,
          body: { organization_id: organization_id },
        })
      );
    });
    yield put(linkOrUnlinkOrganizationSuccess(response));
  } catch (error) {
    yield put(linkOrUnlinkOrganizationFail(error));
  }
}

function* onCreateDevice(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPost({
          url: `${API_URL}/${Endpoints.DEVICES.CREATE}`,
          body: action.payload,
        })
      );
    });
    yield put(createDeviceSuccess(response));
  } catch (error) {
    yield put(createDeviceFail(error));
  }
}

function* onUpdateDevice(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPut({
          url: `${API_URL}/${Endpoints.DEVICES.UPDATE}${action.deviceId}`,
          body: action.payload,
        })
      );
    });
    yield put(updateDeviceSuccess(response));
  } catch (error) {
    yield put(updateDeviceFail(error));
  }
}

function* onFindDeviceStates(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.GET_STATES}`,
        })
      );
    });
    yield put(findDeviceStatesSuccess(response));
  } catch (error) {
    yield put(findDeviceStatesFail(error));
  }
}

function* onFindDeviceTypes(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.GET_TYPES}`,
        })
      );
    });
    yield put(findDeviceTypesSuccess(response));
  } catch (error) {
    yield put(findDeviceTypesFail(error));
  }
}

function* onFindDeviceNetworks(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.GET_NETWORKS}`,
        })
      );
    });
    yield put(findDeviceNetworksSuccess(response));
  } catch (error) {
    yield put(findDeviceNetworksFail(error));
  }
}

function* DeviceSaga() {
  yield takeLatest(CREATE_DEVICE_ACTION, onCreateDevice);
  yield takeLatest(FIND_DEVICE_STATES, onFindDeviceStates);
  yield takeLatest(FIND_DEVICE_TYPES, onFindDeviceTypes);
  yield takeLatest(FIND_DEVICE_NETWORKS, onFindDeviceNetworks);
  yield takeLatest(GET_DEVICES_LIST_DATA, onGetDevicesListData);
  yield takeLatest(GET_DEVICES_UNLINK_LIST_DATA, onGetDeviceUnlinkListData);
  yield takeLatest(LINK_OR_UNLINK_ORGANIZATION, onLinkOrUnlinkOrganization);
  yield takeLatest(UPDATE_DEVICE, onUpdateDevice);
}

export default DeviceSaga;
