import {
  alphanumericRegex,
  alphanumericWithPointRegex,
  alphanumericWithSpecialCharsRegex,
} from "../../../../shared/regexPatterns";

export const validatorValues = [
  { name: "organization_id", required: false },
  {
    name: "device_type_id",
    nameToShow: "tipo",
    required: true,
  },
  {
    name: "device_net_type_id",
    nameToShow: "red",
    required: true,
  },
  {
    name: "lance_number",
    nameToShow: "número de lanza",
    required: false,
    pattern: alphanumericRegex,
    regexMessage: "solo acepta formato alfanumerico",
  },

  {
    name: "eui_id",
    nameToShow: "eui",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "version",
    pattern: alphanumericWithPointRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "name",
    nameToShow: "nombre",
    pattern: alphanumericWithSpecialCharsRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  { name: "device_status_id", required: false },
];
