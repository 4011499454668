const md5 = require("md5");

export const companyFormValues = {
  name: "",
  legal_name: "",
  tax_id: "",
  phone: "",
  email: "",
  calle: "",
  street_number: "",
  floor: "",
  apartment: "",
  zip_code: "",
  municipality_id: "",
  locality_id: "",
  organization_id: null,
  active: true,
};

export const newOrganizationFormValues = {
  name: "",
  legal_name: "",
  tax_id: "",
  zip_code: "",
  locality_id: null,
  municipality_id: null,
  street_name: "",
  street_number: "",
  floor: "",
  apartment: "",
  country_l1_id: null,
  country_l2_id: null,
  phone: "",
  email: "",
  blockchain_enabled: 0,
  prosegur_monitoring: 0,
  active: true,
};

export const newRoleFormValues = {
  permissions: [],
  name: "",
};

const md5Hash = md5("UserPwd");

export const newUserFormValues = {
  role_id: null,
  phone: null,
  fullname: "",
  national_id: "",
  email: "",
  active: false,
  password: md5Hash,
};

export const newDeviceFormValues = {
  organization_id: null,
  device_type_id: null,
  device_net_type_id: null,
  device_status_id: null,
  eui_id: "",
  version: "",
  name: "",
  orden: 1,
  lance_number: null,
  actility_id: "",
};

export const newCountrysidesFormValues = {
  organization_id: null,
  company_id: null,
  name: "",
  lon: 0,
  lat: 0,
  municipality_id: null,
  locality_id: null,
};

export const formattedInitialValues = (
  name: "company" | "device" | "countrysides" | "user",
  values: any
) => {
  const {
    national_id,
    id,
    active,
    email,
    fullname,
    phone,
    role_id,
    username,
    organization_id,
    user_permissions,
  } = values;
  switch (name) {
    case "user":
      return {
        national_id,
        id,
        active,
        email,
        fullname,
        role_id,
        phone,
        organization_id,
        username,
        user_permissions,
      };

    default:
      return values;
  }
};
