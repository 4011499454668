import React, { useEffect, useState } from "react";

import DashboardLogo from "../../assets/img/Dashboard/dashboardLogo.svg";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { useSelector } from "react-redux";
import {
  AuthenticatedRouteProps,
  ReduxType,
  ScreensEnum,
} from "../../shared/types";
import { DashboardMenu, getEquivalent } from "../../shared/helper";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../config/paths";
import { isCRUDdisabled } from "../../shared/screenAuth";
import { Button } from "@mui/material";

export interface MainLayoutInterface extends AuthenticatedRouteProps {
  width: number;
}

const MainLayaout = (props: MainLayoutInterface) => {
  const [username, setUsername] = useState<{
    username: string;
    email: string;
  }>();

  const [currentScreen, setCurrentScreen] = useState<string | undefined>();

  const { children, mainTitle, width, metricHandle, withMetricSave } = props;
  const { userData, rolesData } = useSelector((state: ReduxType) => {
    return state;
  });

  const capitalizeFirstLetter = (path: string) => {
    return path.charAt(0).toUpperCase() + path.slice(1);
  };

  useEffect(() => {
    if (mainTitle) {
      setCurrentScreen(mainTitle);
    } else {
      const location = window.location.pathname;
      let path = capitalizeFirstLetter(location.replace("/", ""));
      switch (path) {
        case "":
          path = "Organizaciones";
          break;
        case "NuevaOrganizacion":
          path = "Nueva Organizacion";
          break;
      }
      if (currentScreen !== path) {
        setCurrentScreen(path);
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (userData.userProfile) {
      let data = getUserProfile();
      setUsername({
        username:
          data &&
          data.username &&
          data.username.substring(0, data.username.indexOf("@")),
        email: data.username,
      });
    }
  }, [userData.userProfile]);

  const getUserProfile = () => {
    return userData &&
      userData.userProfile &&
      typeof userData.userProfile === "string"
      ? JSON.parse(userData.userProfile)
      : userData.userProfile;
  };

  const navigate = useNavigate();

  const firstGridImg = width <= 768 ? 6 : "auto";

  const isRelativeTo = (screenName?: any) => {
    if (screenName === ScreensEnum.NUEVA_ORGANIZACION) {
      return ScreensEnum.ORGANIZACIONES;
    }
    return false;
  };

  return (
    <>
      <Grid container>
        <Grid container style={{ height: 80 }}>
          <Grid
            item
            xs={firstGridImg}
            style={{ background: "#8dbc3e", display: "flex", height: "100%" }}
          >
            <img
              src={DashboardLogo}
              alt={"Dashboard-logo"}
              className={"dashboard-logo"}
            />
          </Grid>
          <Grid item xs className={"dashboard-main-title"}>
            <div className={"dashboard-main-title-content white"}>
              {currentScreen}
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className={"dashboard-main-title"}
            style={{ paddingTop: "2vh", paddingRight: "2vh" }}
          >
            {withMetricSave && (
              <Button
                className="login-button ligth-green"
                variant="contained"
                onClick={metricHandle}
              >
                Guardar
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container style={{ height: "calc(100vh - 80px)" }}>
          <Grid
            item
            xs={firstGridImg}
            className={"dashboard-menu white"}
            style={{ height: "100%" }}
          >
            <div style={{ padding: "1rem" }}>
              <div className="dashboard-menu-username">
                {username && username.username}
              </div>
              <div className="dashboard-menu-email color-gray">
                {" "}
                {username && username.email}
              </div>
            </div>
            <List component="nav" aria-label="secondary mailbox folder">
              {DashboardMenu &&
                DashboardMenu.map((m: ScreensEnum, index: number) => {
                  if (userData.userProfile) {
                    const userProfile =
                      typeof userData.userProfile === "string"
                        ? JSON.parse(userData.userProfile).username
                        : userData.userProfile.username;
                    const isEnabled = isCRUDdisabled(
                      userData.usersList,
                      rolesData.permissionsList,
                      userProfile,
                      Paths[m]
                    );
                    if (!isEnabled)
                      return <React.Fragment key={index}></React.Fragment>;
                  }
                  return (
                    <ListItemButton
                      key={index}
                      selected={currentScreen === m}
                      onClick={(event) => navigate(Paths[m])}
                      // TEMPORAL DISABLED, REMOVE WHEN REPORTS IS FINISHED
                      disabled={Paths[m] === Paths.REPORTS}
                    >
                      <ListItemText
                        key={index}
                        className={`dashboard-menu-item ${
                          currentScreen === m ||
                          isRelativeTo(currentScreen) === m
                            ? "color-green"
                            : "color-dark-gray"
                        }`}
                        primary={getEquivalent(m)}
                      />
                    </ListItemButton>
                  );
                })}
              <ListItemButton
                onClick={() => navigate(Paths.LOGOUT)}
                className={`dashboard-menu-footer`}
              >
                <ListItemText
                  className={`dashboard-menu-item dashboard-menu-item-footer color-dark-gray`}
                  primary={`Cerrar Sesión`}
                />
              </ListItemButton>
            </List>
          </Grid>
          <Grid
            item
            xs
            style={{ width: "100vw", height: "100%", overflowX: "auto" }}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MainLayaout;
