import _, { isArray } from "lodash";
import { findIndex } from "./helper";

const validCuitChars = [
  {
    char: "20",
  },
  { char: "23" },
  { char: "24" },
  { char: "27" },
  { char: "30" },
  { char: "33" },
  { char: "34" },
];

const validateForm = (values: any, validatorValues: any) => {
  let validate = true;
  let arrayVal = [...validatorValues];
  Object.keys(values).forEach((val: any) => {
    let index = findIndex(arrayVal, "name", val);
    if (index !== -1) {
      if (!values[val] && arrayVal[index].required) {
        throw new Error(
          `El campo ${
            arrayVal[index].nameToShow
              ? arrayVal[index].nameToShow
              : val.replace("Id", "")
          } es obligatorio`
        );
      }

      if (!values[val] && !arrayVal[index].required) return;

      if (arrayVal[index].pattern) {
        if (val === "tax_id") {
          validate = verifyCuit(values[val]);
        } else {
          validate = arrayVal[index].pattern.test(values[val]);
        }

        if (!validate) {
          throw new Error(
            `El campo ${
              arrayVal[index].nameToShow
                ? arrayVal[index].nameToShow
                : val.replace("Id", "")
            } ${arrayVal[index].regexMessage && arrayVal[index].regexMessage}`
          );
        }
      }
      if (_.isArray(values[val])) {
        if (values[val].length === 0 && arrayVal[index].required) {
          throw new Error(
            `El campo ${val.replace("Id", "")} se encuentra incompleto`
          );
        }
      }
    }
  });
  return validate;
};

const prepareToastData = async (
  response: any,
  entity: string,
  attr: string,
  determinante: string,
  formBodyAttr?: any,
  update?: boolean
) => {
  const localResponse = isArray(response) ? response[0] : response;
  if (
    (localResponse && localResponse.id) ||
    (localResponse && localResponse.message)
  ) {
    const message =
      localResponse.id && !update
        ? `${determinante} ${entity} #${localResponse[attr]} se creó de forma correcta`
        : localResponse.id && update
        ? `${determinante} ${entity} #${localResponse[attr]} se actualizo de forma correcta`
        : localResponse.message;
    return message;
  } else if (formBodyAttr) {
    return `${determinante} ${entity} #${formBodyAttr} se creó de forma correcta`;
  } else {
    throw new Error(response);
  }
};

const verifyCuit = (value: string) => {
  let valid = false;

  const firstChars = value.substring(0, 2);
  const index = findIndex(validCuitChars, "char", firstChars);
  if (index !== -1) {
    valid = value.length === 11;
  }
  return valid;
};

export { prepareToastData, validateForm, verifyCuit };
