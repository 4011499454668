import { FormControl, Grid, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  alphanumericRegex,
  cuitRegex,
  emailRegex,
  numericRegex,
} from "../../../../shared/regexPatterns";
import { ReduxType } from "../../../../shared/types";
import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";

export interface CompanyFormGridProps {
  onChange: ({ value, name }: { value: any; name: string }) => void;
  editionMode: boolean;
  formValues: any;
}

const CompanyFormGridComponent = (props: CompanyFormGridProps) => {
  const { onChange, formValues, editionMode } = props;

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const getOnChange = async (name: string, id: any) => {
    switch (name) {
      case "municipality_id":
        onChange && onChange({ name: "municipality_id", value: id });
        break;
      case "locality_id":
        onChange && onChange({ name: "locality_id", value: id });
        break;
      default:
        break;
    }
  };

  const onDomicilioChange = ({ value, name }: { value: any; name: string }) => {
    getOnChange(name, value.target.value);
  };

  return (
    <form>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <CustomTextField
            id="tax_id"
            name="tax_id"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.tax_id}
            onChange={onChange}
            required
            label="CUIT sin guiones ni espacios"
            pattern={cuitRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id="name"
            variant="filled"
            name="name"
            pattern={alphanumericRegex}
            required
            style={{ width: "100%" }}
            defaultValue={formValues.name}
            onChange={onChange}
            label="Nombre"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id="legal_name"
            name="legal_name"
            variant="filled"
            pattern={alphanumericRegex}
            style={{ width: "100%" }}
            required
            defaultValue={formValues.legal_name}
            onChange={onChange}
            label="Nombre Legal"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomTextField
            id="phone"
            label="Telefono"
            name="phone"
            pattern={numericRegex}
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.phone}
            onChange={onChange}
            disabled={!editionMode}
            required
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomTextField
            id="email"
            name="email"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.email}
            onChange={onChange}
            required
            label="Email"
            pattern={emailRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <CustomTextField
            id="street_name"
            name="street_name"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.street_name}
            pattern={alphanumericRegex}
            required
            onChange={onChange}
            label="Calle"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="street_number"
            name="street_number"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.street_number}
            onChange={(e) =>
              onChange({ value: parseInt(e.value), name: e.name })
            }
            pattern={numericRegex}
            required
            label="Numero"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="floor"
            name="floor"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.floor}
            onChange={onChange}
            pattern={numericRegex}
            label="Piso"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="apartment"
            name="apartment"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.apartment}
            onChange={onChange}
            label="Departamento"
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="zip_code"
            variant="filled"
            name="zip_code"
            style={{ width: "100%" }}
            defaultValue={formValues.zip_code}
            onChange={(e) =>
              onChange({ value: parseInt(e.value), name: e.name })
            }
            label="Codigo postal"
            disabled={!editionMode}
            required
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="municipality-select-label">Ciudad *</InputLabel>
            <StyledSelect
              labelId="municipality-select-label"
              id="municipality_id"
              name="municipality_id"
              defaultValue={
                formValues.municipality_id ? formValues.municipality_id : ""
              }
              disabled={!editionMode}
              required
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "municipality_id" })
              }
            >
              {organizationsData.cities &&
                organizationsData.cities.length > 0 &&
                organizationsData.cities.map((value: any) => {
                  return <MenuItem value={value.id}>{value.name}</MenuItem>;
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="locality-select-label">Localidad *</InputLabel>
            <StyledSelect
              labelId="locality-select-label"
              id="locality_id"
              name="locality_id"
              required
              defaultValue={
                formValues.locality_id ? formValues.locality_id : ""
              }
              disabled={!editionMode}
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "locality_id" })
              }
            >
              {organizationsData &&
                organizationsData.localities.length > 0 &&
                organizationsData.localities
                  .filter(
                    (l) => l.municipality_id === formValues.municipality_id
                  )
                  .map((value: any) => {
                    return <MenuItem value={value.id}>{value.name}</MenuItem>;
                  })}
            </StyledSelect>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyFormGridComponent;
