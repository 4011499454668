import { Grid, GridSpacing } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { ResponsiveStyleValue } from "@mui/system";
import React, { CSSProperties } from "react";

export interface LayoutGridBoxProps extends DefaultComponentProps<any> {
  children: JSX.Element;
  className?: string;
  header?: JSX.Element;
  style?: CSSProperties;
  rowSpacing?: ResponsiveStyleValue<GridSpacing>;
  alignItems?: string;
  justifyContent?: string;
}

const LayoutGridBoxComponent = ({
  style,
  children,
  className,
  header,
  ...props
}: LayoutGridBoxProps) => {
  return (
    <Grid
      container
      className={`layout-grid-box ${className ? className : ""}`}
      style={style}
      {...props}
    >
      {header && (
        <Grid item xs={12}>
          {header}
        </Grid>
      )}
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default LayoutGridBoxComponent;
