import { Button, FormControl, Grid, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  alphanumericRegex,
  floatPositiveAndNegativeRegex,
} from "../../../../shared/regexPatterns";
import { ReduxType } from "../../../../shared/types";

import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";

export interface CompanyFormGridProps {
  onChange: ({ value, name }: { value: any; name: string }) => void;
  editionMode: boolean;
  organizations: any[];
  organizationId?: any;
  formValues: any;
}

const CountrysidesFormGridComponent = (props: CompanyFormGridProps) => {
  const { onChange, formValues, editionMode, organizationId, organizations } =
    props;

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const getOnChange = async (name: string, id: any) => {
    switch (name) {
      case "municipality_id":
        onChange && onChange({ name: "municipality_id", value: id });
        break;
      case "locality_id":
        onChange && onChange({ name: "locality_id", value: id });
        break;
      default:
        break;
    }
  };

  const onDomicilioChange = ({ value, name }: { value: any; name: string }) => {
    getOnChange(name, value.target.value);
  };

  return (
    <form onSubmit={() => {}}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="organization-select-label">Organización</InputLabel>
            <StyledSelect
              labelId="organization-select-label"
              id="organization_id"
              name="organization_id"
              defaultValue={
                formValues.organization_id ? formValues.organization_id : ""
              }
              onChange={(e) =>
                onChange({ value: e.target.value, name: "organization_id" })
              }
              disabled={!editionMode || !!organizationId}
            >
              {organizations &&
                organizations.map((o, index: number) => {
                  return (
                    <MenuItem key={`index-${index}`} value={o.id}>
                      {o.name}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="company-select-label">Compañia</InputLabel>
            <StyledSelect
              labelId="company-select-label"
              id="company_id"
              name="company_id"
              value={formValues.company_id ? formValues.company_id : ""}
              onChange={(e) =>
                onChange({ value: e.target.value, name: "company_id" })
              }
              disabled={!editionMode}
            >
              {organizationsData.companiesList &&
                organizationsData.companiesList.map((o, index: number) => {
                  return (
                    <MenuItem key={`index-${index}`} value={o.id}>
                      {o.name}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            id="name"
            variant="filled"
            name="name"
            pattern={alphanumericRegex}
            required
            style={{ width: "100%" }}
            defaultValue={formValues.name}
            onChange={onChange}
            label="Nombre"
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="municipality-select-label">Ciudad *</InputLabel>
            <StyledSelect
              labelId="municipality-select-label"
              id="municipality_id"
              name="municipality_id"
              defaultValue={
                formValues.municipality_id ? formValues.municipality_id : ""
              }
              disabled={!editionMode}
              required
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "municipality_id" })
              }
            >
              {organizationsData.cities &&
                organizationsData.cities.length > 0 &&
                organizationsData.cities.map((value: any) => {
                  return <MenuItem value={value.id}>{value.name}</MenuItem>;
                })}
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="locality-select-label">Localidad *</InputLabel>
            <StyledSelect
              labelId="locality-select-label"
              id="locality_id"
              name="locality_id"
              required
              defaultValue={
                formValues.locality_id ? formValues.locality_id : ""
              }
              disabled={!editionMode}
              onChange={(e) =>
                onDomicilioChange({ value: e, name: "locality_id" })
              }
            >
              {organizationsData.localities &&
                organizationsData.localities.length > 0 &&
                organizationsData.localities
                  .filter(
                    (l) => l.municipality_id === formValues.municipality_id
                  )
                  .map((value: any, index: number) => {
                    return <MenuItem value={value.id}>{value.name}</MenuItem>;
                  })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={6} lg={3}>
          <CustomTextField
            id="lat"
            label="Latitud"
            name="lat"
            pattern={floatPositiveAndNegativeRegex}
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.lat}
            onChange={(e) =>
              onChange({ value: parseFloat(e.value), name: e.name })
            }
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={6} lg={3}>
          <CustomTextField
            id="lon"
            label="Longitud"
            name="lon"
            pattern={floatPositiveAndNegativeRegex}
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.lon}
            onChange={(e) =>
              onChange({ value: parseFloat(e.value), name: e.name })
            }
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={6} lg={6}>
          <Grid container alignItems={"center"}>
            <Grid item xs={6}>
              Posicion geográfica
            </Grid>
            <Grid item xs={6}>
              <Button
                className="login-button ligth-green "
                style={{ margin: "auto" }}
                variant="contained"
              >
                VER MAPA
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CountrysidesFormGridComponent;
