import { Button, CircularProgress, Divider, Grid, Modal } from "@mui/material";

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Endpoints } from "../../../config/endpoints";
import { API_WOZALABS_URL } from "../../../config/general-config";
import { apiPatch, apiPost } from "../../../shared/ApiService";
import { newRoleFormValues } from "../../../shared/FormValues";
import {
  IPermissions,
  ReduxType,
  RolesPermissionsPATCH,
} from "../../../shared/types";
import { prepareToastData, validateForm } from "../../../shared/validations";
import { getPermissionsListAction } from "../../../store/roles/actions";
import { ToastComponent } from "../../toast";
import { LayoutGridBoxComponent } from "../layoutGridBox";
import { validatorValues } from "./constants/Constants";
import { RolFormGridComponent } from "./rolFormGrid";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -60%)",
  width: 800,
  backgroundColor: "#FFFFFF",
};

export interface Props {
  createMode: boolean;
  open: boolean;
  editionMode: boolean;
  setEditionMode: (val: boolean) => void;
  handleClose: (message?: string) => void;
  rolToEditOrView?: {
    name?: string;
    id?: number;
    permissions: IPermissions[];
  };
}

const NewRoleComponent = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<any>(newRoleFormValues);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isRequestSubmitting, setIsRequestSubmitting] = useState(false);

  const dispatch = useDispatch();

  const { rolesData } = useSelector((state: ReduxType) => {
    return state;
  });

  const {
    createMode,
    rolToEditOrView,
    editionMode,
    handleClose,
    open,
    setEditionMode,
  } = props;

  useEffect(() => {
    if (!createMode && rolToEditOrView) {
      initialUpdate(rolToEditOrView, false);
    }
    if (createMode && editionMode) {
      initialUpdate(newRoleFormValues, false);
    }
  }, [createMode, editionMode, rolToEditOrView]);

  useEffect(() => {
    if (!rolesData.permissionsList) {
      getAllRolData();
    }
  }, []);

  const getAllRolData = async () => {
    await dispatch(getPermissionsListAction());
  };

  const onChange = ({ value, name }: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const initialUpdate = async (rolToEditOrView: any, loading: boolean) => {
    const formattedPermissions = rolToEditOrView.permissions
      .filter(
        (val: any) =>
          val.active && val.role_permissions && val.role_permissions.active
      )
      .map((val: any) => val.id);
    await setFormValues({
      name: rolToEditOrView.name,
      permissions: formattedPermissions,
    });
    await setLoading(loading);
  };

  const rolePermissionsRequests = async (
    method: "POST" | "PATCH",
    values: any,
    active?: boolean
  ) => {
    if (method === "PATCH" && (values.length === 0 || !values)) return;
    const valuesToString =
      method === "PATCH" &&
      values &&
      values.map(({ id }: any) => id).toString();
    switch (method) {
      case "POST":
        await apiPost({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ROLES.ROLE_PERMISSIONS}`,
          body: values,
        });
        break;
      case "PATCH":
        await apiPatch({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ROLES.ROLE_PERMISSIONS_IN}${valuesToString})`,
          body: { active },
        });
        break;
      default:
        break;
    }
  };

  const makePATCHrequests = async ({
    toACTIVE,
    toINACTIVE,
    toPOST,
  }: RolesPermissionsPATCH) => {
    await rolePermissionsRequests("PATCH", toACTIVE, true);
    await rolePermissionsRequests("PATCH", toINACTIVE, false);
    await rolePermissionsRequests("POST", toPOST);
  };

  const rolesPermissions = (values: {
    name?: string;
    id?: number;
    permissions: IPermissions[];
  }) => {
    const roleId = rolToEditOrView && rolToEditOrView.id;
    const permissions: { role_id?: number; permission_id: number }[] =
      formValues.permissions.map((val: number) => ({
        ...(roleId ? { role_id: roleId } : {}),
        permission_id: val,
      }));
    const toPOST = permissions.filter(
      (x) => !values.permissions.some((i) => i.id === x.permission_id)
    );
    const noPOST = permissions.filter((x) =>
      values.permissions.some((i) => i.id === x.permission_id)
    );
    const toINACTIVE = values.permissions
      .filter((x) => !noPOST.some((i) => i.permission_id === x.id))
      .map(({ role_permissions }) => ({
        active: false,
        id: role_permissions!.id,
      }));
    const toACTIVE = values.permissions
      .filter((x) =>
        noPOST.some(
          (i) =>
            x.id === i.permission_id &&
            !(x.role_permissions && x.role_permissions.active)
        )
      )
      .map(({ role_permissions }) => ({
        active: true,
        id: role_permissions!.id,
      }));
    return { toINACTIVE, toACTIVE, toPOST };
  };

  const onSubmit = async () => {
    try {
      setIsRequestSubmitting(true);
      if (!validateForm(formValues, validatorValues)) {
        throw new Error(
          "Complete todos los datos obligatorios antes de continuar."
        );
      }
      const body = {
        name: formValues.name,
      };
      let response: any = undefined;

      if (createMode) {
        response = await apiPost({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ROLES.POST}`,
          body,
        });
        if (response) {
          const permissions: { role_id?: number; permission_id: number }[] =
            formValues.permissions.map((val: number) => ({
              role_id: response[0].id,
              permission_id: val,
            }));
          await rolePermissionsRequests("POST", permissions);
          response = { message: "El Rol fue creado exitosamente" };
        }
      } else {
        response = await apiPatch({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ROLES.DELETE}${
            rolToEditOrView!.id
          }`,
          body,
        });
        if (response) {
          const { toACTIVE, toINACTIVE, toPOST } = rolesPermissions(
            rolToEditOrView!
          );
          await makePATCHrequests({ toACTIVE, toINACTIVE, toPOST });
          response = { message: "El Rol fue modificado exitosamente" };
        }
      }
      const message = await prepareToastData(response, "Rol", "id", "El");
      handleClose(message);
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    } finally {
      setIsRequestSubmitting(false);
    }
  };

  const tabName = () => {
    if (createMode) {
      return "Nuevo Rol";
    }
    if (editionMode) {
      return "Editar Nombre del Rol";
    }
    return "Nombre del Rol";
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12} md={4}>
              <span className={`modal-main-title fw-5`}>{tabName()}</span>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <LayoutGridBoxComponent>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={5}>
                        <Button
                          className="login-button text-button"
                          style={{ width: 130 }}
                          variant="text"
                          color="info"
                          onClick={() => handleClose()}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className="login-button ligth-green "
                          style={{ margin: "auto", width: 130 }}
                          variant="contained"
                          disabled={isRequestSubmitting}
                          title={isRequestSubmitting ? "Loading..." : undefined}
                          onClick={() => {
                            if (editionMode) {
                              onSubmit();
                            } else {
                              setEditionMode(true);
                            }
                          }}
                        >
                          {isRequestSubmitting ? (
                            <CircularProgress
                              style={{ width: 24, height: 24 }}
                              className="color-green"
                            />
                          ) : editionMode ? (
                            "Guardar"
                          ) : (
                            "Editar"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </LayoutGridBoxComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12}>
              {!loading && formValues && (
                <RolFormGridComponent
                  permissionsList={rolesData.permissionsList}
                  formValues={formValues}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  editionMode={editionMode}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default NewRoleComponent;
