import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReduxType, UserVerifyAccountRequest } from "../../shared/types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../core/auth/AuthService";
import { Paths } from "../../config/paths";
import { verifyUserAction } from "../../store/users/actions";
import { ToastComponent } from "../toast";

const VerifyAccountComponent = (props: any) => {
  const { buttonLabel, verifyType } = props;

  const [validated, setValidated] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [userFormLogin, setUserFormLogin] = useState<UserVerifyAccountRequest>({
    type: verifyType,
    email: undefined,
    token: undefined,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (isLoggedIn()) {
      if (verifyType === "recovery") {
        navigate(Paths.RESET);
      } else {
        navigate(Paths.ORGANIZATION);
      }
    }
  }, [userData.user]);

  useEffect(() => {
    if (userData.error.message) {
      showErrors(userData.error.message);
    }
  }, [userData.error.message]);

  const handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      !userFormLogin.email ||
      !userFormLogin.token
    ) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      doVerifyRequest();
    }
  };

  const doVerifyRequest = async () => {
    try {
      await dispatch(verifyUserAction(userFormLogin));
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const showErrors = async (error: string) => {
    await setErrorMessage(error);
    await setShowToast(true);
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="standard-required"
            label="Email"
            type="email"
            variant="standard"
            margin="normal"
            error={
              userFormLogin.email === "" ||
              (userFormLogin.email === undefined && !validated)
            }
            onChange={(e) =>
              setUserFormLogin({
                ...userFormLogin,
                email: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="standard-password-input"
            label="Código"
            type="text"
            variant="standard"
            margin="normal"
            error={
              userFormLogin.token === "" ||
              (userFormLogin.token === undefined && !validated)
            }
            onChange={(e) =>
              setUserFormLogin({
                ...userFormLogin,
                token: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            className="login-button ligth-green"
            variant="contained"
            onClick={handleSubmit}
          >
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default VerifyAccountComponent;
