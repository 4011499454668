import { CSSProperties } from "react";
import { findIndex } from "../../../../shared/helper";
import {
  alphanumericRegex,
  alphanumericWithSpecialCharsRegex,
  cuitRegex,
  dniRegex,
  emailRegex,
  floatPositiveAndNegativeRegex,
  numericRegex,
} from "../../../../shared/regexPatterns";
import { GridColumns } from "../../../../shared/types";

export const resetData = {
  name: "",
  legal_name: "",
  tax_id: "",
  phone: "",
  email: "",
  blockchain_enabled: 0,
  prosegur_monitoring: 0,
  street_name: "",
  street_number: "",
  floor: "",
  apartment: "",
  zip_code: "",
  locality_id: "",
};

export const validatorValues = [
  {
    name: "name",
    nameToShow: "nombre",
    pattern: alphanumericWithSpecialCharsRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "legal_name",
    nameToShow: "nombre legal",
    pattern: alphanumericWithSpecialCharsRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "tax_id",
    required: true,
    nameToShow: "CUIT",
    pattern: cuitRegex,
    regexMessage: "no es valido",
  },
  {
    name: "phone",
    nameToShow: "telefono",
    pattern: numericRegex,
    required: true,
    regexMessage: "solo acepta valores numericos",
  },
  {
    name: "email",
    pattern: emailRegex,
    required: true,
    regexMessage: "solo acepta formato de email. Ej: example@gmail.com",
  },
  {
    name: "street_name",
    nameToShow: "nombre de la calle",
    pattern: alphanumericWithSpecialCharsRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "street_number",
    nameToShow: "numero de la calle",
    pattern: numericRegex,
    required: true,
    regexMessage: "solo acepta valores numericos",
  },
  {
    name: "floor",
    nameToShow: "piso",
    required: false,
    pattern: numericRegex,
    regexMessage: "solo acepta valores numericos",
  },
  { name: "apartment", required: false },
  { name: "zip_code", nameToShow: "Código postal", required: true },
  { name: "locality_id", required: true },
  { name: "blockchain_enabled", required: false },
  { name: "prosegur_monitoring", required: false },
];

export const validatorValuesUser = [
  { name: "organization_id", nameToShow: "organizacion", required: true },
  {
    name: "fullname",
    nameToShow: "nombre y apellido",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "phone",
    nameToShow: "telefono",
    pattern: numericRegex,
    required: false,
    regexMessage: "solo acepta valores numericos",
  },
  {
    name: "email",
    pattern: emailRegex,
    required: true,
    regexMessage: "solo acepta formato de email. Ej: example@gmail.com",
  },
  { name: "role_id", nameToShow: "rol", required: true },
  {
    name: "national_id",
    nameToShow: "DNI",
    pattern: dniRegex,
    required: true,
    regexMessage:
      "solo acepta formato de dni sin espacios ni puntos. Ej: 27845745",
  },
  { name: "active", required: false },
];

export const validatorValuesCountrysides = [
  {
    name: "organizacion_id",
    nameToShow: "organizacion",
    required: false,
  },
  {
    name: "company_id",
    nameToShow: "compania",
    required: false,
  },
  {
    name: "name",
    nameToShow: "nombre",
    pattern: alphanumericWithSpecialCharsRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios.",
  },
  {
    name: "lon",
    pattern: floatPositiveAndNegativeRegex,
    required: false,
  },
  {
    name: "lat",
    pattern: floatPositiveAndNegativeRegex,
    required: false,
  },

  {
    name: "municipality_id",
    nameToShow: "ciudad",
    required: true,
  },
  {
    name: "locality_id",
    nameToShow: "localidad",
    required: true,
  },
];

export const validatorValuesCompany = [
  {
    name: "name",
    nameToShow: "nombre",
    pattern: alphanumericWithSpecialCharsRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "legal_name",
    nameToShow: "nombre legal",
    pattern: alphanumericWithSpecialCharsRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios.",
  },
  {
    name: "tax_id",
    nameToShow: "CUIT",
    pattern: cuitRegex,
    required: true,
    regexMessage:
      "solo acepta formato de cuit: Ej: 20-39.845.612-1 sin puntos ni barras.",
  },
  {
    name: "email",
    pattern: emailRegex,
    required: true,
    regexMessage: "solo acepta formato de email. Ej: example@gmail.com",
  },
  {
    name: "phone",
    nameToShow: "telefono",
    pattern: numericRegex,
    required: true,
    regexMessage: "solo acepta formato numerico.",
  },
  {
    name: "street_name",
    nameToShow: "nombre de la calle",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "street_number",
    nameToShow: "numero de la calle",
    pattern: numericRegex,
    required: true,
    regexMessage: "solo acepta formato numerico.",
  },
  {
    name: "floor",
    pattern: numericRegex,
    nameToShow: "piso",
    regexMessage: "solo acepta formato numerico.",
    required: false,
  },
  {
    name: "apartment",
    required: false,
  },
  {
    name: "zip_code",
    nameToShow: "codigo postal",
    required: true,
  },
  {
    name: "municipality_id",
    nameToShow: "ciudad",
    required: true,
  },
  {
    name: "locality_id",
    nameToShow: "localidad",
    required: true,
  },
];

export const companiesColumns: GridColumns[] = [
  { id: "name", label: "Nombre" },
  { id: "tax_id", label: "CUIT" },
  { id: "acciones", label: "Acciones" },
];

export const devicesColumns: GridColumns[] = [
  { id: "id", label: "ID" },
  {
    id: "tipoId",
    label: "Tipo",
    width: 90,
    render: (value: any, record: any) => {
      return <>{record.device_types.name}</>;
    },
  },
  { id: "name", label: "Nombre", width: 110 },
  {
    id: "estadoId",
    label: "Estado",
    render: (value: any, record: any) => {
      const activeStyle: CSSProperties = {
        padding: 5,
        background: "rgba(139, 195, 74, 0.2)",
        borderRadius: 3,
        textAlign: "center",
        color: "#6BAA00",
      };
      const inactiveStyle: CSSProperties = {
        color: "#C3604A",
        background: "rgba(195, 96, 74, 0.2)",
        textAlign: "center",
        padding: 5,
        borderRadius: 3,
      };
      return (
        <div
          style={
            record.device_statuses.name === "Deshabilitado"
              ? inactiveStyle
              : activeStyle
          }
        >
          {record.device_statuses.name === "Deshabilitado" ? "Broken" : "Ok"}
        </div>
      );
    },
  },
  { id: "acciones", label: "Acciones" },
];

export const usersColumns = (companiesList: any[]): GridColumns[] => [
  {
    id: "username",
    label: "Nombre",
    render: (value: any, record: any) => {
      return (
        <div style={{ display: "flex", flexDirection: "column", width: 160 }}>
          <b title={record.fullname || "-"}>{record.fullname || "-"}</b>
          <p title={record.email || "-"} style={{ margin: 0 }}>
            {record.email || "-"}
          </p>
        </div>
      );
    },
  },
  {
    id: "datos",
    label: "Datos",
    render: (value: any, record: any) => {
      return (
        <div style={{ display: "flex", flexDirection: "column", width: 110 }}>
          <b title={`DNI: ${record.national_id || "-"}`}>
            DNI: {record.national_id || "-"}
          </b>
          <p title={record.phone || "-"} style={{ margin: 0 }}>
            {record.phone || "-"}
          </p>
        </div>
      );
    },
  },
  {
    id: "compania",
    label: "Compañía",
    render: (value: any, record: any) => {
      const organizacion = companiesList.find(
        (val) => val.id === record.companiaId
      );
      return (
        <div
          style={{ width: 130 }}
          title={(organizacion && organizacion.name) || "-"}
        >
          {(organizacion && organizacion.name) || "-"}
        </div>
      );
    },
  },
  {
    id: "roles",
    label: "Rol",
    render: (value: any) => {
      return (
        <div style={{ width: 130 }} title={(value && value.name) || "-"}>
          {(value && value.name) || "-"}
        </div>
      );
    },
  },
  { id: "acciones", label: "Acciones" },
];

export const countrysidesColumns = (companiesList: any[]): GridColumns[] => [
  { id: "name", label: "Nombre", width: 160 },
  {
    id: "companiaId",
    label: "Compañía",
    render: (value: any, record: any) => {
      const organization =
        companiesList &&
        companiesList.find((val) => val.id === record.company_id);
      return (
        <div
          style={{ width: 100 }}
          className="wrap"
          title={(organization && organization.name) || "-"}
        >
          {(organization && organization.name) || "-"}
        </div>
      );
    },
    width: 100,
  },

  { id: "acciones", label: "Acciones" },
];

export const formatCountrysidesValues = (values: any, localities: any) => {
  const { company_id, name, lon, lat, locality_id } = values;
  let country_l2_id = null;
  const index = findIndex(localities, "id", locality_id);
  if (index !== -1) {
    country_l2_id = localities[index].country_l2_id;
  }
  return {
    company_id,
    name,
    lon,
    lat,
    country_l2_id,
  };
};

export const formatOrganizationValues = (values: any) => {
  const {
    name,
    legal_name,
    tax_id,
    phone,
    email,
    blockchain_enabled,
    prosegur_monitoring,
    street_name,
    floor,
    street_number,
    locality_id,
    apartment,
    zip_code,
  } = values;
  return {
    name,
    legal_name,
    tax_id,
    phone,
    email,
    blockchain_enabled,
    prosegur_monitoring,
    address: {
      street_name,
      street_number,
      floor,
      apartment,
      zip_code,
      locality_id,
    },
  };
};

export const formatCompanyValues = (values: any) => {
  const {
    name,
    legal_name,
    tax_id,
    phone,
    email,
    street_name,
    street_number,
    floor,
    apartment,
    municipality_id,
    locality_id,
    zip_code,
    organization_id,
    active,
  } = values;
  return {
    name,
    legal_name,
    tax_id,
    phone,
    email,
    address: {
      street_name,
      street_number,
      floor,
      apartment,
      zip_code,
      municipality_id,
      locality_id,
    },
    organization_id,
    active,
  };
};
