import React from "react";

import { Grid } from "@mui/material";
import { MetricConfigComponent } from "../MetricConfigComponent";

const style = {
  backgroundColor: "#FFFFFF",
};

const MetricComponent = (props: any) => {
  const { alertType, width } = props;

  return (
    <>
      <Grid style={{ padding: "1rem", ...style }} className="white">
        <MetricConfigComponent
          short_name={"HUM"}
          alertType={alertType}
          title={"Humedad"}
          width={width}
          alertMinAndMax={[-100, 100]}
          alertValues={[-100, -50, -20, 20, 50, 100]}
        />

        <MetricConfigComponent
          short_name={"TEMP"}
          alertType={alertType}
          title={"Temperatura"}
          width={width}
          alertMinAndMax={[-20, 50]}
          alertValues={[-20, -10, 5, 35, 45, 50]}
        />

        <MetricConfigComponent
          short_name={"CO2"}
          alertType={alertType}
          title={"Dióxido de carbono"}
          width={width}
          alertMinAndMax={[-100, 100]}
          alertValues={[-100, -50, -20, 20, 50, 100]}
        />

        <MetricConfigComponent
          short_name={"MOV"}
          alertType={alertType}
          title={"Movimiento"}
          width={width}
          alertMinAndMax={[-100, 100]}
          alertValues={[-100, -50, -20, 20, 50, 100]}
        />
      </Grid>
    </>
  );
};

export default MetricComponent;
