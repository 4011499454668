import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
} from "@mui/material";

import { Box } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReduxType } from "../../../shared/types";
import { StyledSelect } from "../../formInputs/SelectComponent/StyledSelect";
import { ToastComponent } from "../../toast";
import { LayoutGridBoxComponent } from "../layoutGridBox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -60%)",
  width: 800,
  height: 250,
  backgroundColor: "#FFFFFF",
};

const DeviceLinkComponent = (props: any) => {
  const [formValues, setFormValues] = useState<any>();
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const { linkOrUnlinkOrganization, handleClose, open } = props;

  const onChange = ({ value, name }: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  const onSubmit = async () => {
    linkOrUnlinkOrganization(formValues.organization_id);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12} md={4}>
              <span className={`modal-main-title fw-5`}>
                Vincular Organizacion
              </span>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <LayoutGridBoxComponent>
                    <Grid
                      container
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={5}>
                        <Button
                          className="login-button text-button"
                          style={{ width: 130 }}
                          variant="text"
                          color="info"
                          onClick={handleClose}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className="login-button ligth-green "
                          style={{ margin: "auto", width: 130 }}
                          variant="contained"
                          onClick={() => {
                            onSubmit();
                          }}
                          disabled={!formValues}
                        >
                          Vincular
                        </Button>
                      </Grid>
                    </Grid>
                  </LayoutGridBoxComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="p-2"
          >
            <Grid item xs={12}>
              <form>
                <Grid item xs={12}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="organization-select-label">
                      Organización
                    </InputLabel>
                    <StyledSelect
                      labelId="organization-select-label"
                      id="organization_id"
                      name="organization_id"
                      onChange={(e) =>
                        onChange({
                          value: e.target.value,
                          name: "organization_id",
                        })
                      }
                      required
                    >
                      {organizationsData.organizationsList &&
                        organizationsData.organizationsList.length > 0 &&
                        organizationsData.organizationsList.map(
                          (o, index: number) => {
                            return (
                              <MenuItem
                                key={`select-org-${index}`}
                                value={o.id}
                              >
                                {o.name}
                              </MenuItem>
                            );
                          }
                        )}
                    </StyledSelect>
                  </FormControl>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DeviceLinkComponent;
