import styled from "@emotion/styled";
import { Select } from "@mui/material";

export const StyledSelect = styled(Select)(() => ({
  "& label.Mui-disabled": {
    color: "#585757",
  },
  "& .MuiFilledInput-input.Mui-disabled": {
    "-webkit-text-fill-color": "#585757",
  },
  "& div": {
    backgroundColor: "#f4f4f4 !important",
  },
  "& .MuiFilledInput-root": {
    "background-color": "#f4f4f4 !important",
    "&.Mui-disabled fieldset": {
      "-webkit-text-fill-color": "#585757",
    },
  },
}));
