import {
  getCurrentProfileUser,
  getCurrentUser,
  getToken,
} from "../../core/auth/AuthService";
import * as types from "./actionTypes";

const initialState = {
  user: { username: getCurrentUser(), access_token: getToken() },
  usersList: undefined,
  userCreateOrUpdateResponse: undefined,
  userProfile: getCurrentProfileUser(),
  userReset: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const UserReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_USERS_LIST:
    case types.GET_USERS_LIST_FAIL:
    case types.CLEAN_USER:
    case types.LOGIN_USER:
    case types.LOGIN_PROFILE_USER:
    case types.RESET_USER:
    case types.CREATE_USER:
    case types.UPDATE_USER:
    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE:
    case types.VERIFY_USER_ACTION:
      state = { ...state, loading: true };
      break;

    case types.GET_USERS_LIST_SUCCESS:
      state = { ...state, loading: false, usersList: actionPayload };
      break;

    case types.LOGIN_USER_SUCCESS:
      state = {
        ...state,
        user: actionPayload,
        userProfile: actionPayload,
        loading: false,
      };
      break;

    case types.RESET_USER_SUCCESS:
      state = { ...state, loading: false };
      break;

    case types.LOGIN_PROFILE_SUCCESS:
      state = {
        ...state,
        userProfile: actionPayload,
        loading: false,
      };
      break;

    case types.CLEAN_USER_SUCCESS:
      state = {
        ...state,
        userReset: undefined,

        loading: false,
      };
      break;

    case types.CREATE_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      state = {
        ...state,
        userCreateOrUpdateResponse: actionPayload,
        loading: false,
      };
      break;

    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS:
      state = {
        ...state,
        userCreateOrUpdateResponse: undefined,
        loading: false,
      };
      break;

    case types.VERIFY_USER_SUCCESS:
      state = {
        ...state,
        user: actionPayload,
        userProfile: actionPayload,
        loading: false,
      };
      break;

    case types.CLEAN_USER_FAIL:
    case types.LOGIN_USER_FAIL:
    case types.LOGIN_PROFILE_FAIL:
    case types.RESET_USER_FAIL:
    case types.CREATE_USER_FAIL:
    case types.UPDATE_USER_FAIL:
    case types.VERIFY_USER_FAIL:
    case types.CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;
    case types.DELETE_USER:
      state = { ...state, loading: true };
      break;
    case types.DELETE_USER_FAIL:
      state = { ...state, loading: false, error: { message: actionPayload } };
      break;
    case types.DELETE_USER_SUCCESS:
      state = { ...state, loading: false };
      break;
  }

  return state;
};

export default UserReducer;
