import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_URL, API_WOZALABS_URL } from "../../config/general-config";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
  genericRequest,
} from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  cleanCreateOrUpdateResponseFail,
  cleanCreateOrUpdateResponseSuccess,
  cleanOrganizationDataFail,
  cleanOrganizationDataSucess,
  createOrganizationFail,
  createOrganizationSuccess,
  deleteOrganizationFail,
  deleteOrganizationSuccess,
  getCitiesListDataFail,
  getCitiesListDataSuccess,
  getCompaniesDataFail,
  getCompaniesDataSuccess,
  getLocalitiesListDataFail,
  getLocalitiesListDataSuccess,
  getOrganizationDataFail,
  getOrganizationDataSuccess,
  getOrganizationDevicesFail,
  getOrganizationDevicesSuccess,
  getOrganizationsListDataFail,
  getOrganizationsListDataSuccess,
  getProvincesListDataFail,
  getProvincesListDataSuccess,
  updateOrganizationDataFail,
  updateOrganizationDataSuccess,
} from "./actions";
import {
  CLEAN_CREATE_OR_UPDATE_RESPONSE,
  CLEAN_ORGANIZATION_DATA,
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  GET_CITY,
  GET_COMPANIES_DATA,
  GET_LOCALITY,
  GET_ORGANIZATIONS_LIST_DATA,
  GET_ORGANIZATION_DATA,
  GET_ORGANIZATION_DEVICES_DATA,
  GET_PROVINCE,
  UPDATE_ORGANIZATION,
} from "./actionTypes";

function* onGetOrganizationsListData() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ORGANIZATIONS.GET}`,
        })
      );
    });
    yield put(getOrganizationsListDataSuccess(response));
  } catch (error) {
    yield put(getOrganizationsListDataFail(error));
  }
}

function* onGetProvincesListData(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        genericRequest("api", Endpoints.HELPERS.PROVINCE, "GET", {}, () => {})
      );
    });
    yield put(
      getProvincesListDataSuccess({
        response: response,
        cleanData: action.cleanData,
      })
    );
  } catch (error) {
    yield put(getProvincesListDataFail(error));
  }
}

function* onGetCities(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      const url = action.provinceId
        ? `${API_URL}/api/${Endpoints.HELPERS.CITY}/${action.provinceId}`
        : `${API_WOZALABS_URL}/${Endpoints.WOZALABS.HELPERS.CITY}`;

      return Promise.resolve(apiGet({ url: url }));
    });
    yield put(
      getCitiesListDataSuccess({
        response: response,
        cleanData: action.cleanData,
      })
    );
  } catch (error) {
    yield put(getCitiesListDataFail(error));
  }
}

function* onGetLocalities(action: any) {
  try {
    let response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.HELPERS.LOCALITY}`,
        })
      );
    });
    yield put(getLocalitiesListDataSuccess(response));
  } catch (error) {
    yield put(getLocalitiesListDataFail(error));
  }
}

function* onCreateOrganization(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPost({
          url: `${API_URL}/api/${Endpoints.ORGANIZATIONS.GET_LIST}`,
          body: action.payload,
        })
      );
    });
    yield put(createOrganizationSuccess(response));
  } catch (error) {
    yield put(createOrganizationFail(error));
  }
}

function* onUpdateOrganization(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiPut({
          url: `${API_URL}/api/${Endpoints.ORGANIZATIONS.GET_LIST}/${action.organizationId}`,
          body: action.payload,
        })
      );
    });
    yield put(updateOrganizationDataSuccess(response));
  } catch (error) {
    yield put(updateOrganizationDataFail(error));
  }
}

function* onDeleteOrganization(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiDelete({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ORGANIZATIONS.DELETE}${action.organizationId}`,
        })
      );
    });
    yield put(deleteOrganizationSuccess(response));
  } catch (error) {
    yield put(deleteOrganizationFail(error));
  }
}

function* onCleanOrUpdateResponseData() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanCreateOrUpdateResponseSuccess());
  } catch (error) {
    yield put(cleanCreateOrUpdateResponseFail(error));
  }
}

function* onCleanOrganizationData() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanOrganizationDataSucess());
  } catch (error) {
    yield put(cleanOrganizationDataFail(error));
  }
}

function* onGetOrganizationData(action: any) {
  try {
    const { org_id } = action;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ORGANIZATIONS.GET_ORGANIZATION_DATA}${org_id}`,
        })
      );
    });
    yield put(getOrganizationDataSuccess(response));
  } catch (error) {
    yield put(getOrganizationDataFail(error));
  }
}

function* onGetOrganizationDeviceData(action: any) {
  try {
    const { org_id } = action;
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.GET_BY_ORG}${org_id}`,
        })
      );
    });
    yield put(getOrganizationDevicesSuccess(response));
  } catch (error) {
    yield put(getOrganizationDevicesFail(error));
  }
}

function* onGetCompaniesData(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/api/${Endpoints.COMPANIES}`,
        })
      );
    });
    yield put(getCompaniesDataSuccess(response));
  } catch (error) {
    yield put(getCompaniesDataFail(error));
  }
}

function* OrganizationsSaga() {
  yield takeLatest(GET_ORGANIZATIONS_LIST_DATA, onGetOrganizationsListData);
  yield takeLatest(CREATE_ORGANIZATION, onCreateOrganization);
  yield takeLatest(
    CLEAN_CREATE_OR_UPDATE_RESPONSE,
    onCleanOrUpdateResponseData
  );
  yield takeLatest(CLEAN_ORGANIZATION_DATA, onCleanOrganizationData);
  yield takeLatest(UPDATE_ORGANIZATION, onUpdateOrganization);
  yield takeLatest(DELETE_ORGANIZATION, onDeleteOrganization);
  yield takeLatest(GET_PROVINCE, onGetProvincesListData);
  yield takeLatest(GET_CITY, onGetCities);
  yield takeLatest(GET_LOCALITY, onGetLocalities);
  yield takeLatest(GET_ORGANIZATION_DATA, onGetOrganizationData);
  yield takeLatest(GET_ORGANIZATION_DEVICES_DATA, onGetOrganizationDeviceData);
  yield takeLatest(GET_COMPANIES_DATA, onGetCompaniesData);
}

export default OrganizationsSaga;
