import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Box,
} from "@mui/material";

import React, { CSSProperties } from "react";

interface TextFieldProps {
  name: string;
  id: string;
  defaultValue?: any;
  variant?: "filled" | "standard" | "outlined";
  style?: CSSProperties;
  className?: string;
  onChange?: (e: { value: any; name: string }) => void;
  required?: boolean;
  label?: string;
  placeholder?: string;
  pattern?: RegExp;
  disabled?: boolean;
  options: any;
}

const SelectFieldComponent = (props: TextFieldProps) => {
  const [localValue, setLocalValue] = React.useState(props.defaultValue);
  const [error, setError] = React.useState(false);

  const { id, disabled, label, pattern, onChange, defaultValue, options } =
    props;

  React.useEffect(() => {
    if (defaultValue) {
      setLocalValue(defaultValue);
      onChange && onChange({ value: defaultValue, name: props.name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleChange = (e: any) => {
    setLocalValue(e.target.value);
    onChange && onChange({ value: e.target.value, name: props.name });
  };

  return (
    <div>
      <FormControl variant="filled" fullWidth>
        <InputLabel id={label}>Name</InputLabel>
        <Select
          labelId={label}
          id={id}
          value={defaultValue}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
              {selected.map((value: any) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          <MenuItem aria-label="None" value="" />
          {options &&
            options.length > 0 &&
            options.map((value: any, index: number) => (
              <MenuItem value={value.id} key={`select-city-${index}`}>
                {value.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectFieldComponent;
