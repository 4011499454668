import React from "react";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";
import { VerifyAccountComponent } from "../../components/verify";

const VerifyResetPage = (props: any) => {
  return (
    <AuthenticatedRoute withHeader path={Paths.VERIFYRESETACCOUNT}>
      <VerifyAccountComponent
        verifyType={"recovery"}
        buttonLabel={"Resetear"}
      />
    </AuthenticatedRoute>
  );
};

export default VerifyResetPage;
