export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USERS_LIST_FAIL = "GET_USERS_LIST_FAIL";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";

export const LOGIN_PROFILE_USER = "LOGIN_PROFILE_USER";
export const LOGIN_PROFILE_FAIL = "LOGIN_PROFILE_FAIL";
export const LOGIN_PROFILE_SUCCESS = "LOGIN_PROFILE_SUCCESS";

export const RESET_USER = "RESET_USER";
export const RESET_USER_FAIL = "RESET_USER_FAIL";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";

export const CLEAN_USER = "CLEAN_USER";
export const CLEAN_USER_FAIL = "CLEAN_USER_FAIL";
export const CLEAN_USER_SUCCESS = "CLEAN_USER_SUCCESS";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const CLEAN_CREATE_OR_UPDATE_RESPONSE =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE";
export const CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL";
export const CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS";

export const VERIFY_USER_ACTION = "VERIFY_USER_ACTION";
export const VERIFY_USER_FAIL = "VERIFY_USER_FAIL";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
