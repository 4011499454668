import React, { useEffect, useState } from "react";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";
import { loginUserAction } from "../../store/users/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ReduxType, UserLoginRequest } from "../../shared/types";
import { isLoggedIn } from "../../core/auth/AuthService";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const LoginPage = (props: any) => {
  const [validated, setValidated] = useState(true);

  const [userFormLogin, setUserFormLogin] = useState<UserLoginRequest>({
    username: undefined,
    password: undefined,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(Paths.ORGANIZATION);
    }
  }, [userData.user]);

  const handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    if (!userFormLogin.username || !userFormLogin.password) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      doLoginRequest();
    }
  };

  const doLoginRequest = async () => {
    await dispatch(loginUserAction(userFormLogin));
  };

  return (
    <AuthenticatedRoute withHeader path={Paths.LOGIN}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="standard-required"
            label="Email"
            type="email"
            variant="standard"
            margin="normal"
            error={
              userFormLogin.username === "" ||
              (userFormLogin.username === undefined && !validated)
            }
            onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
            onChange={(e) =>
              setUserFormLogin({
                ...userFormLogin,
                username: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="standard-password-input"
            label="Password"
            type="password"
            variant="standard"
            margin="normal"
            error={
              userFormLogin.password === "" ||
              (userFormLogin.password === undefined && !validated)
            }
            onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
            onChange={(e) =>
              setUserFormLogin({
                ...userFormLogin,
                password: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            className="login-button ligth-green"
            variant="contained"
            onClick={handleSubmit}
          >
            Ingresar
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          className="d-inline-flex flex-wrap"
          style={{ marginTop: 16 }}
        >
          <span className="w-500">¿OLVIDASTE TU CONTRASEÑA?</span>
          <Link to={Paths.RECOVER} className={"login-link"}>
            RECUPERAR CONTRASEÑA
          </Link>
        </Grid>
      </Grid>
    </AuthenticatedRoute>
  );
};

export default LoginPage;
