import React, { useEffect, useState } from "react";

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { renderHandleColor, renderTrackerColor } from "./constants/constants";

const sliderStyle: any = {
  // Give the slider some width
  position: "relative",
  width: "100%",
  height: 80,
};

const railStyle: any = {
  position: "absolute",
  width: "100%",
  height: 10,
  marginTop: 35,
  borderRadius: 5,
  backgroundColor: "#8B9CB6",
};

const Tick = ({ tick, count }: any) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 52,
          marginLeft: -0.5,
          width: 1,
          height: 8,
          backgroundColor: "silver",
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: 60,
          fontSize: 10,
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {tick.value}
      </div>
    </div>
  );
};

const Handle = ({ handle: { id, value, percent }, getHandleProps }: any) => {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: -15,
        marginTop: 25,
        zIndex: 2,
        width: 28,
        height: 28,
        border: 0,
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "50%",
        backgroundColor: renderHandleColor(id),
        color: "#000000",
      }}
      {...getHandleProps(id)}
    >
      <div
        className={`fw-bold-600`}
        style={{
          fontFamily: "'Poppins', sans-serif",
          fontSize: 12,
          marginTop: -30,
        }}
      >
        {value}
      </div>
    </div>
  );
};

const Track = ({ key, source, target, getTrackProps }: any) => {
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 35,
        backgroundColor: renderTrackerColor(source.id),
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
};

const SliderRangerComponent = (props: any) => {
  const [values, setValues] = useState();
  const [update, setUpdate] = useState();

  const { alertMinAndMax, alertValues, showTicks, tickSteps } = props;

  useEffect(() => {
    if (alertValues) {
      setValues(alertValues.slice());
      setUpdate(alertValues.slice());
    }
  }, [alertValues]);
  const onUpdate = (update: any) => {
    setUpdate(update);
  };

  const onChange = (values: any) => {
    setValues(values);
  };

  return (
    <>
      {values ? (
        <Slider
          rootStyle={sliderStyle}
          domain={alertMinAndMax}
          step={1}
          mode={2}
          onUpdate={onUpdate}
          onChange={onChange}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => (
              <div style={railStyle} {...getRailProps()} />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }: any) => (
              <div className="slider-handles">
                {handles.map((handle: any) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => {
                  return (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  );
                })}
              </div>
            )}
          </Tracks>
          {showTicks ? (
            <Ticks
              count={
                tickSteps
                  ? tickSteps
                  : 15 /* generate approximately {tickSteps} ticks within the domain */
              }
            >
              {({ ticks }) => (
                <div className="slider-ticks">
                  {ticks.map((tick) => (
                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                  ))}
                </div>
              )}
            </Ticks>
          ) : (
            <></>
          )}
        </Slider>
      ) : (
        <></>
      )}
    </>
  );
};

export default SliderRangerComponent;
