const AlertType = [
  { name: "Estándar", accordionTitle: "" },
  { name: "Estándar por zona", accordionTitle: "Desagregado por zona" },
  { name: "Estándar por especie", accordionTitle: "Desagregado por especie" },
  {
    name: "Estándar por zona y especie",
    accordionTitle: "Desagregado por zona y especie",
  },
];

export { AlertType };
