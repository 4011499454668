import { alphanumericRegex } from "../../../../shared/regexPatterns";

export const validatorValues = [
  {
    name: "name",
    pattern: alphanumericRegex,
    required: true,
    regexMessage: "solo acepta formato alfanumerico con espacios",
  },
  {
    name: "permissions",
    nameToShow: "permisos",
    required: false,
  },
];
