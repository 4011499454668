import { UserLoginRequest, UserVerifyAccountRequest } from "../../shared/types";
import * as types from "./actionTypes";

export const loginUserAction = (user: UserLoginRequest) => {
  return {
    type: types.LOGIN_USER,
    ...user,
  };
};

export const loginUserFail = (error: any) => {
  return {
    type: types.LOGIN_USER_FAIL,
    payload: error,
  };
};

export const loginUserSuccess = (user: any) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload: user,
  };
};

export const getUsersListAction = () => {
  return {
    type: types.GET_USERS_LIST,
  };
};

export const getUsersListFail = (error: any) => {
  return {
    type: types.GET_USERS_LIST_FAIL,
    payload: error,
  };
};

export const getUsersListSuccess = (dashboardList: any) => {
  return {
    type: types.GET_USERS_LIST_SUCCESS,
    payload: dashboardList,
  };
};

export const cleanStateAction = () => {
  return {
    type: types.CLEAN_USER,
  };
};

export const cleanStateActionFail = (error: any) => {
  return {
    type: types.CLEAN_USER_FAIL,
    payload: error,
  };
};

export const cleanStateActionSuccess = () => {
  return {
    type: types.CLEAN_USER_SUCCESS,
  };
};

export const deleteUserAction = (userId: number) => {
  return { type: types.DELETE_USER, userId };
};

export const deleteUserFail = (error: any) => {
  return { type: types.DELETE_USER_FAIL, payload: error };
};

export const deleteUserSuccess = (payload: any) => {
  return { type: types.DELETE_USER_SUCCESS, payload };
};

export const cleanCreateOrUpdateResponseAction = () => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE };
};

export const cleanCreateOrUpdateResponseFail = (error: any) => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL, payload: error };
};

export const cleanCreateOrUpdateResponseSuccess = () => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS };
};

export const verifyUserAction = (verifyData: UserVerifyAccountRequest) => {
  return { type: types.VERIFY_USER_ACTION, payload: verifyData };
};

export const verifyUserFail = (error: any) => {
  return { type: types.VERIFY_USER_FAIL, payload: error };
};

export const verifyUserSuccess = (payload: any) => {
  return { type: types.VERIFY_USER_SUCCESS, payload };
};

export const resetUserAction = (userData: any) => {
  return { type: types.RESET_USER, payload: userData };
};

export const resetUserFail = (error: any) => {
  return { type: types.RESET_USER_FAIL, payload: error };
};

export const resetUserSuccess = (payload: any) => {
  return { type: types.RESET_USER_SUCCESS, payload };
};
