import React from "react";

import VIEW from "../../../assets/icons/svg/GridActions/view.svg";
import EDIT from "../../../assets/icons/svg/GridActions/edit.svg";
import { InputSwitchComponent } from "../../inputSwitch";
import { isArray } from "lodash";
import { ConfirmationDeleteIcon } from "../../confirmationDeleteIcon";

interface GridActionsProps {
  data: any;
  actions: { toggle?: any; edit: boolean; view: boolean; delete: boolean };
  id: number;
  width?: number;
  align?: "center" | "right";
  onClick?: (value: "VIEW" | "EDIT" | "DELETE", e?: any) => void;
}

const GridActions = ({
  data,
  onClick,
  actions,
  align,
  width,
  id,
}: GridActionsProps) => {
  let val;

  if (actions && actions.toggle && actions.toggle.name) {
    val =
      data && data[actions.toggle.name] && isArray(data[actions.toggle.name])
        ? data[actions.toggle.name][0]
        : data[actions.toggle.name];
  }
  return (
    <>
      <div
        className="grid-actions"
        style={{
          justifyContent: !!align
            ? align === "right"
              ? "flex-end"
              : align === "center"
              ? "center"
              : ""
            : "unset",
          width,
        }}
      >
        {actions.view && (
          <img
            src={VIEW}
            alt="view-icon"
            onClick={() => onClick && onClick("VIEW")}
            title="View"
          />
        )}
        {actions.edit && (
          <img
            src={EDIT}
            alt="edit-icon"
            onClick={() => onClick && onClick("EDIT")}
            title="Edit"
          />
        )}
        {!!actions.toggle && (
          <InputSwitchComponent
            onChange={(e: any) => onClick && onClick("DELETE", e)}
            initial={val}
          />
        )}
        {actions.delete && (
          <ConfirmationDeleteIcon
            onClick={() => onClick && onClick("DELETE")}
            id={id}
          />
        )}
      </div>
    </>
  );
};
export default GridActions;
