export const GET_ORGANIZATIONS_LIST_DATA = "GET_ORGANIZATIONS_LIST_DATA";
export const GET_ORGANIZATIONS_LIST_DATA_FAIL =
  "GET_ORGANIZATIONS_LIST_DATA_FAIL";
export const GET_ORGANIZATIONS_LIST_DATA_SUCCESS =
  "GET_ORGANIZATIONS_LIST_DATA_SUCCESS";

export const GET_ORGANIZATION_DATA = "GET_ORGANIZATION_DATA";
export const GET_ORGANIZATION_DATA_FAIL = "GET_ORGANIZATION_DATA_FAIL";
export const GET_ORGANIZATION_DATA_SUCCESS = "GET_ORGANIZATION_DATA_SUCCESS";

export const GET_ORGANIZATION_DEVICES_DATA = "GET_ORGANIZATION_DEVICES_DATA";
export const GET_ORGANIZATION_DEVICES_DATA_FAIL =
  "GET_ORGANIZATION_DEVICES_DATA_FAIL";
export const GET_ORGANIZATION_DEVICES_DATA_SUCCESS =
  "GET_ORGANIZATION_DEVICES_DATA_SUCCESS";

export const GET_COMPANIES_DATA = "GET_COMPANIES_DATA";
export const GET_COMPANIES_DATA_FAIL = "GET_COMPANIES_DATA_FAIL";
export const GET_COMPANIES_DATA_SUCCESS = "GET_COMPANIES_DATA_SUCCESS";

export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const CREATE_ORGANIZATION_FAIL = "CREATE_ORGANIZATION_FAIL";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";

export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_FAIL = "UPDATE_ORGANIZATION_FAIL";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";

export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const DELETE_ORGANIZATION_FAIL = "DELET_ORGANIZATION_FAIL";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";

export const CLEAN_CREATE_OR_UPDATE_RESPONSE =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE";
export const CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL";
export const CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS =
  "CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS";

export const GET_PROVINCE = "GET_PROVINCE";
export const GET_PROVINCE_FAIL = "GET_PROVINCE_FAIL";
export const GET_PROVINCE_SUCCESS = "GET_PROVINCE_SUCCESS";

export const GET_CITY = "GET_CITY";
export const GET_CITY_FAIL = "GET_CITY_FAIL";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";

export const GET_LOCALITY = "GET_LOCALITY";
export const GET_LOCALITY_FAIL = "GET_LOCALITY_FAIL";
export const GET_LOCALITY_SUCCESS = "GET_LOCALITY_SUCCESS";

export const CLEAN_ORGANIZATION_DATA = "CLEAN_ORGANIZATION_DATA";
export const CLEAN_ORGANIZATION_DATA_FAIL = "CLEAN_ORGANIZATION_DATA_FAIL";
export const CLEAN_ORGANIZATION_DATA_SUCCESS =
  "CLEAN_ORGANIZATION_DATA_SUCCESS";
