import { formatDate } from "../../../shared/helper";

interface Column {
  id: "id" | "tax_id" | "name" | "created_at" | "compania" | "acciones";
  label: string;
  width?: number;
  align?: "right";
  render?: (data: any, record: any) => JSX.Element;
  format?: (value: number) => string;
}

export const columns: readonly Column[] = [
  { id: "id", label: "ID", width: 20 },
  { id: "tax_id", label: "CUIT", width: 100 },
  {
    id: "name",
    label: "Nombre",
    width: 200,
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "created_at",
    label: "Fecha de creación",
    width: 200,
    format: (value: any) => formatDate(value) || "",
  },
  {
    id: "acciones",
    label: "Acciones",
    width: 50,
  },
];
