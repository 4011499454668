import {
  Grid,
  InputAdornment,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  paperClasses,
  tableCellClasses,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { CSSProperties } from "react";
import { CustomButton } from "../../customButton";
import { CustomLinkProps, ReduxType } from "../../../shared/types";
import { GridActions } from "../gridActions";
import _ from "lodash";
import { useSelector } from "react-redux";
import { findIndex } from "../../../shared/helper";

export interface GenericGridProps {
  columns: readonly any[];
  style?: CSSProperties;
  searchCallback?: any;
  displayData?: any[];
  actions?: { view: boolean; delete: boolean; edit: boolean };
  CustomTableRow?: JSX.Element;
  CustomTableCell?: JSX.Element;
  headerButtons?: CustomLinkProps[];
  header?: boolean;
  rowsPerPage?: number;
  hasSearchInput?: boolean;
  selectedRows?: any;
  setSelectedRows?: any;
  onActionClick?: (
    action: "VIEW" | "DELETE" | "EDIT",
    record: any
  ) => Promise<void>;
}

const StyledTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "#6BAA00",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6BAA00",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#6BAA00",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6BAA00",
    },
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${paperClasses.rounded}`]: {
    boxShadow: "none",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    lineHeight: "unset",
  },
}));

const GenericGrid = ({
  headerButtons,
  header,
  hasSearchInput,
  onActionClick,
  columns,
  searchCallback,
  displayData,
  actions,
  CustomTableCell,
  CustomTableRow,
  rowsPerPage,
  style,
  selectedRows,
  setSelectedRows,
}: GenericGridProps) => {
  const [tableData, setTableData] = React.useState(displayData);
  const [searchValue, setSearchValue] = React.useState("");
  const [page, setPage] = React.useState(0);

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  React.useEffect(() => {
    if (searchValue) {
      setTableData(searchValueFilter(displayData, searchValue));
    } else {
      setTableData(displayData);
    }
  }, [displayData, searchValue]);

  const searchValueFilter = (array: any[] | undefined, value: any) =>
    array && array.filter((val) => searchCallback(val, value));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleActionClick = (
    value: "VIEW" | "EDIT" | "DELETE",
    record: any
  ) => {
    onActionClick && onActionClick(value, record);
  };

  const handleSelectedRow = (id: number, value: boolean) => {
    if (value) {
      setSelectedRows([...selectedRows, id]);
    } else {
      const index = selectedRows.indexOf(id);
      if (index !== -1) {
        selectedRows.splice(index, 1);
        setSelectedRows([...selectedRows]);
      }
    }
  };

  const handleSearch = (e: any) => {
    const value = e.target.value.toUpperCase();
    setSearchValue(value);
  };

  const finalRowsPerPage = rowsPerPage ? rowsPerPage : 25;

  const showOrgName = (orgId: number) => {
    if (
      organizationsData &&
      organizationsData.organizationsList &&
      organizationsData.organizationsList.length > 0
    ) {
      const index = findIndex(organizationsData.organizationsList, "id", orgId);
      if (index !== -1) {
        return organizationsData.organizationsList[index].name;
      }
    }
    return "-";
  };

  return (
    <Grid style={{ padding: "1rem", ...style }} className="white">
      {header && (
        <Grid container>
          <Grid
            item
            xs={
              !headerButtons || (headerButtons && headerButtons.length === 0)
                ? 12
                : 4
            }
          >
            {hasSearchInput && (
              <StyledTextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                id="standard-search"
                label="Buscar"
                onChange={handleSearch}
                type="search"
                variant="filled"
              />
            )}
          </Grid>
          {headerButtons && headerButtons.length !== 0 && (
            <>
              <Grid item xs={3}></Grid>
              <Grid
                item
                xs={5}
                display="flex"
                alignItems="center"
                justifyContent={
                  headerButtons && headerButtons.length > 1
                    ? "space-around"
                    : "flex-end"
                }
              >
                {headerButtons &&
                  headerButtons.length > 0 &&
                  headerButtons.map((value, id) => {
                    return (
                      <CustomButton
                        key={`button-${id}`}
                        {...value}
                        style={{
                          width: `${
                            (headerButtons.length > 1 ? 90 : 50) /
                            headerButtons.length
                          }%`,
                        }}
                        startAdornment={false}
                        id={id}
                      />
                    );
                  })}
              </Grid>
            </>
          )}
        </Grid>
      )}

      <StyledPaper
        className={header ? "mt-2" : ""}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer style={{ maxHeight: 532 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell key={column.id} align={column.align}>
                      {column.label}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                _.isArray(tableData) &&
                tableData.length > 0 &&
                tableData
                  .slice(
                    page * finalRowsPerPage,
                    page * finalRowsPerPage + finalRowsPerPage
                  )
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        className="table-column-custom"
                      >
                        {columns &&
                          columns.map((column) => {
                            const value = !row[column.id]
                              ? "-"
                              : row[column.id];
                            if (column.render) {
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.render(value, row)}
                                </TableCell>
                              );
                            }
                            switch (column.id) {
                              case "acciones":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <GridActions
                                      data={value}
                                      align={column.align}
                                      width={column.width}
                                      actions={
                                        actions
                                          ? actions
                                          : {
                                              view: true,
                                              delete: true,
                                              edit: false,
                                            }
                                      }
                                      onClick={(action) =>
                                        handleActionClick(action, row)
                                      }
                                      id={row.id}
                                      key={row.id}
                                    />
                                  </TableCell>
                                );
                              case "checkbox":
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    <div style={{ width: column.width }}>
                                      <Checkbox
                                        checked={selectedRows.includes(
                                          row[column.idValue]
                                        )}
                                        onChange={(event) =>
                                          handleSelectedRow(
                                            row[column.idValue],
                                            event.target.checked
                                          )
                                        }
                                      />
                                    </div>
                                  </TableCell>
                                );
                              default:
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    title={
                                      column.format
                                        ? column.format(value)
                                        : value
                                    }
                                  >
                                    <div
                                      className="wrap"
                                      style={{ width: column.width }}
                                    >
                                      <p>
                                        {column.format
                                          ? column.format(value)
                                          : column.showOrgName
                                          ? showOrgName(value)
                                          : value}
                                      </p>
                                    </div>
                                  </TableCell>
                                );
                            }
                          })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={
            tableData && tableData.length > 0
              ? tableData.slice(
                  page * finalRowsPerPage,
                  page * finalRowsPerPage + finalRowsPerPage
                ).length
              : 0
          }
          rowsPerPage={finalRowsPerPage}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={handleChangePage}
        />
      </StyledPaper>
    </Grid>
  );
};

export default GenericGrid;
