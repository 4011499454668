import React, { useState } from "react";

import { CustomLinkProps, ReduxType } from "../../../shared/types";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrganizationAction,
  getOrganizationsListDataAction,
  cleanOrganizationDataAction,
  getCitiesListDataAction,
  getLocalitiesListDataAction,
} from "../../../store/organizations/actions";
import { GenericGrid } from "../genericGrid";
import { ToastComponent } from "../../toast";
import { columns } from "./Columns";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";

const OrganizationListComponent = (props: any) => {
  const [showToast, setShowToast] = useState(false);
  const [localMessage, setLocalMessage] = useState<any>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const { organizationsList } = organizationsData;

  const { state }: any = useLocation();

  React.useEffect(() => {
    if (state && state.message && localMessage !== state.message) {
      setLocalMessage(state.message);
      setShowToast(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getOrganizationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (organizationsData.cities && organizationsData.cities.length === 0) {
      getCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      organizationsData.localities &&
      organizationsData.localities.length === 0
    ) {
      getLocalities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCities = async () => {
    await dispatch(getCitiesListDataAction(null, false));
  };

  const getLocalities = async () => {
    await dispatch(getLocalitiesListDataAction(null));
  };

  const getOrganizationsList = async () => {
    await dispatch(getOrganizationsListDataAction());
    await dispatch(cleanOrganizationDataAction());
  };

  const headerButtons: CustomLinkProps[] = [
    {
      onClick: () => {
        navigate(Paths.NEW_ORGANIZATION, {
          state: {
            createMode: true,
            editionMode: true,
          },
        });
      },

      id: "newOrganization",
      label: "Nueva organización",
      type: "SIMPLE",
      className: "login-button ligth-green",
      variant: "contained",
    },
  ];

  const onActionClick = async (
    action: "VIEW" | "DELETE" | "EDIT",
    record: any
  ) => {
    switch (action) {
      case "EDIT":
        navigate(Paths.NEW_ORGANIZATION, {
          state: {
            editMode: true,
            record: {
              ...record,
              street_name: record.address.street_name,
              street_number: record.address.street_number,
              floor: record.address.floor,
              apartment: record.address.apartment,
              zip_code: record.address.zip_code,
              locality_id: record.address.locality_id,
              municipality_id: record.address.municipality_id,
            },
          },
        });
        break;
      case "VIEW":
        navigate(Paths.NEW_ORGANIZATION, {
          state: {
            viewMode: true,
            record: {
              ...record,
              ...record.address,
            },
          },
        });
        break;
      case "DELETE":
        await dispatch(deleteOrganizationAction(record.id));
        setLocalMessage("Usuario eliminado exitosamente");
        setShowToast(true);
        setTimeout(async () => {
          await getOrganizationsList();
        }, 500);
        break;
      default:
        break;
    }
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setLocalMessage(undefined);
  };

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={false}
        bodyContent={localMessage}
      />
      <GenericGrid
        headerButtons={headerButtons}
        columns={columns}
        displayData={organizationsList}
        searchCallback={(val: any, value: any) =>
          val.id === parseInt(value) ||
          (val.tax_id && val.tax_id.toUpperCase().indexOf(value) > -1) ||
          (val.name && val.name.toUpperCase().indexOf(value) > -1)
        }
        header={true}
        hasSearchInput={true}
        onActionClick={onActionClick}
      />
    </>
  );
};

export default OrganizationListComponent;
