import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { ReduxType } from "../../shared/types";
import { AlertType } from "../../components/metric/constants/Constants";
import { MetricComponent } from "../../components/metric";

const MetricPage = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [alertType, setAlertType] = useState<string>(AlertType[0].name);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  const verifyLoading = () => {
    return userData.loading;
  };

  const onSaveMetric = () => {
    console.log("asf");
  };

  return (
    <AuthenticatedRoute
      withHeader
      withMetricSave
      path={Paths.METRIC}
      loading={verifyLoading()}
      mainTitle={"Métricas alertas"}
      metricHandle={onSaveMetric}
    >
      <div style={{ padding: "1rem" }}>
        <Grid>
          <MetricComponent
            alertType={alertType}
            width={width}
            setAlertType={setAlertType}
          />
        </Grid>
      </div>
    </AuthenticatedRoute>
  );
};

export default MetricPage;
