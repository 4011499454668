import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import {
  alphanumericRegex,
  alphanumericWithPointRegex,
  alphanumericWithSpecialCharsRegex,
} from "../../../../shared/regexPatterns";
import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";

export interface FormGridProps {
  states: any[];
  networks: any[];
  types: any[];
  organizations: any[];
  editionMode?: boolean;
  onChange: ({ value, name }: { value: any; name: string }) => void;
  formValues: any;
  organizationId?: number;
  fromOrganization?: boolean;
}

const FormGridComponent = (props: FormGridProps) => {
  const {
    onChange,
    formValues,
    fromOrganization,
    editionMode,
    networks,
    types,
    organizations,
    organizationId,
  } = props;

  return (
    <form>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        {!fromOrganization && (
          <Grid item xs={12}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="organization-select-label">
                Organización
              </InputLabel>
              <StyledSelect
                labelId="organization-select-label"
                id="organization_id"
                name="organization_id"
                value={
                  organizationId
                    ? organizationId
                    : formValues.organization_id !== null
                    ? formValues.organization_id
                    : ""
                }
                onChange={(e) =>
                  onChange({ value: e.target.value, name: "organization_id" })
                }
                required
                disabled={!editionMode || !!organizationId}
              >
                {organizations &&
                  organizations.length > 0 &&
                  organizations.map((o, index: number) => {
                    return (
                      <MenuItem key={`select-org-${index}`} value={o.id}>
                        {o.name}
                      </MenuItem>
                    );
                  })}
              </StyledSelect>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="type-select-label">Tipo *</InputLabel>
            <StyledSelect
              required
              id="device_type_id"
              name="device_type_id"
              labelId="tipo-select-label"
              defaultValue={
                formValues.device_type_id
                  ? formValues.device_type_id
                  : formValues.dispositivo_tipo
                  ? formValues.dispositivo_tipo.id
                  : ""
              }
              disabled={!editionMode}
              onChange={(e) =>
                onChange({ value: e.target.value, name: "device_type_id" })
              }
            >
              {types &&
                types.length > 0 &&
                types.map((t, index: number) => {
                  return (
                    <MenuItem key={`select-type-${index}`} value={t.id}>
                      {t.name}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="net-select-label">Red * </InputLabel>
            <StyledSelect
              labelId="net-select-label"
              id="device_net_type_id"
              name="device_net_type_id"
              defaultValue={
                formValues.device_net_type_id
                  ? formValues.device_net_type_id
                  : ""
              }
              onChange={(e) =>
                onChange({ value: e.target.value, name: "device_net_type_id" })
              }
              required
              disabled={!editionMode}
            >
              {networks &&
                networks.length > 0 &&
                networks.map((n, index: number) => {
                  return (
                    <MenuItem key={`select-type-network-${index}`} value={n.id}>
                      {n.name}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={4}>
          <CustomTextField
            id="lance_number"
            name="lance_number"
            label="Número de lanza"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.lance_number}
            onChange={onChange}
            pattern={alphanumericRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            required
            id="eui_id"
            name="eui_id"
            label="EUI"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.eui_id}
            onChange={onChange}
            pattern={alphanumericRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            required
            id="version"
            name="version"
            label="Versión"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.version}
            onChange={onChange}
            pattern={alphanumericWithPointRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            required
            id="name"
            name="name"
            label="Nombre"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.name}
            onChange={onChange}
            pattern={alphanumericWithSpecialCharsRegex}
            disabled={!editionMode}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.device_status_id}
                disabled={!editionMode}
                onChange={(e) =>
                  onChange({
                    value: !formValues.device_status_id,
                    name: "device_status_id",
                  })
                }
              />
            }
            label="Activo"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default FormGridComponent;
