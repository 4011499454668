import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import backgroundDesktop from "../../assets/img/LoginPage/backgrounIOFCompany.svg";
import backgroundMobile from "../../assets/img/LoginPage/backgroundMobile.svg";
import IOFLogo from "../../assets/icons/svg/LoginPage/login-logo.svg";

import { useNavigate } from "react-router-dom";
import { ReduxType } from "../../shared/types";
import { Paths } from "../../config/paths";

const LoginLayaout = (props: any) => {
  const [show, setShow] = useState(false);
  const [errorType, setErrorType] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  const { children, width } = props;
  const navigate = useNavigate();

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (userData.error && userData.error.message) {
      setAndShowError(userData.error.message, true);
    }
  }, [userData.error]);

  useEffect(() => {
    if (userData.userReset) {
      setAndShowError(userData.userReset, false);
      navigate(Paths.LOGIN);
    }
  }, [userData.userReset]);

  const setAndShowError = (error: any, errorType: boolean) => {
    setErrorType(errorType);
    setErrorMessage(error);
    toggleShow();
  };

  const toggleShow = () => setShow(!show);

  return (
    <section
      className="login-section"
      style={
        width <= 768
          ? {
              background: `bottom / contain no-repeat url(${backgroundMobile})`,
            }
          : {
              background: `right / contain no-repeat url(${backgroundDesktop})`,
            }
      }
    >
      <div className="login-form">
        <img
          src={IOFLogo}
          alt={"Login-logo"}
          style={{
            height: 96,
            width: "213.23",
          }}
        />
        {children}
      </div>
      <div className="login-background"></div>
    </section>
  );
};

export default LoginLayaout;
