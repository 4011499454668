import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Endpoints } from "../../../config/endpoints";
import { API_URL_AUTH, API_WOZALABS_URL } from "../../../config/general-config";
import { Paths } from "../../../config/paths";
import { apiGet, apiPatch, apiPost, apiPut } from "../../../shared/ApiService";
import { prepareToastData, validateForm } from "../../../shared/validations";
import {
  getCitiesListDataAction,
  getLocalitiesListDataAction,
  getOrganizationDataAction,
  getOrganizationDevicesAction,
  getOrganizationsListDataAction,
} from "../../../store/organizations/actions";
import { CustomButton } from "../../customButton";
import { Modal } from "../../modal";
import { ToastComponent } from "../../toast";
import { LayoutGridBoxComponent } from "../layoutGridBox";

import {
  companiesColumns,
  formatCompanyValues,
  formatCountrysidesValues,
  formatOrganizationValues,
  resetData,
  validatorValues,
  validatorValuesCompany,
  validatorValuesCountrysides,
  validatorValuesUser,
} from "./constants/Constants";
import { validatorValues as validatorDeviceValue } from "../newDevice/constants/Constant";
import { OrganizationFormGridComponent } from "./organizationFormGrid";
import { ModalFormHandler } from "./modalFormHandler";
import {
  companyFormValues,
  newDeviceFormValues,
  newCountrysidesFormValues,
  newOrganizationFormValues,
  newUserFormValues,
  formattedInitialValues,
} from "../../../shared/FormValues";
import {
  getDeviceUnlinkListDataAction,
  linkOrUnlinkOrganizationAction,
} from "../../../store/devices/actions";
import { ModalProps, ReduxType } from "../../../shared/types";
import {
  cloneWithoutReference,
  findCity,
  findIndex,
  getOrganizationTitle,
} from "../../../shared/helper";
import { GenericGrid } from "../genericGrid";
import { RightColumn } from "./layout/RightColumn";

const NewOrganizationComponent = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<any>(newOrganizationFormValues);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [successMessage, setSuccessMessage] = useState<any>();
  const [editionModeModal, setEditionModeModal] = useState(false);
  const [createModeModal, setCreateModeModal] = useState(false);
  const [linkModeModal, setLinkModeModal] = useState(false);
  const [viewModeModal, setViewModeModal] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>({
    open: false,
    submitName: undefined,
  });
  const [modalFormValues, setModalFormValues] = useState<any>();
  const [localEditMode, setLocalEditMode] = useState(false);
  const [selectedFromOrgRows, setSelectedFromOrgRows] = useState([]);
  const [toSaveAfterOrg, setToSaveAfterOrg] = useState<
    {
      method: string;
      body: any;
    }[]
  >([]);
  const [isSubmittingModal, setIsSubmittingModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deviceData, organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const { state }: any = useLocation();
  const { createMode, editionMode, record } = state;

  console.log({ record });
  console.log({ formValues });

  useEffect(() => {
    if (record && formValues.name === "") {
      initialUpdate(record, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  useEffect(() => {
    if (loading && createMode) {
      initialUpdate(resetData, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMode]);

  useEffect(() => {
    if (!deviceData.devicesUnlink) {
      getUnlinkDeviceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData.devicesUnlink]);

  React.useEffect(() => {
    getOrganizationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      organizationsData.cities &&
      organizationsData.cities.length > 0 &&
      organizationsData.localities &&
      organizationsData.localities.length > 0 &&
      formValues.name !== "" &&
      record
    ) {
      setFormValues(findCity(formValues, organizationsData, "organization"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.name]);

  const getOrganizationsList = async () => {
    await dispatch(getOrganizationsListDataAction());
  };

  const getUnlinkDeviceList = async () => {
    await dispatch(getDeviceUnlinkListDataAction());
  };

  useEffect(() => {
    getAllNewOrganizationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (organizationsData.cities && organizationsData.cities.length === 0) {
      getCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      organizationsData.localities &&
      organizationsData.localities.length === 0
    ) {
      getLocalities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCities = async () => {
    await dispatch(getCitiesListDataAction(null, false));
  };

  const getLocalities = async () => {
    await dispatch(getLocalitiesListDataAction(null));
  };

  const getAllNewOrganizationData = async () => {
    if (record && record.id) {
      await dispatch(getOrganizationDataAction(record.id));
      await dispatch(getOrganizationDevicesAction(record.id));
    }
  };

  const initialUpdate = async (orgData: any, loading: boolean) => {
    await setFormValues({
      tax_id: orgData.tax_id,
      name: orgData.name,
      legal_name: orgData.legal_name,
      phone: orgData.phone,
      email: orgData.email,
      blockchain_enabled: orgData.blockchain_enabled,
      prosegur_monitoring: orgData.prosegur_monitoring,
      street_name: orgData.street_name,
      street_number: orgData.street_number,
      floor: orgData.floor,
      apartment: orgData.apartment,
      zip_code: orgData.zip_code,
      locality_id: orgData.locality_id,
      municipality_id: orgData.municipality_id,
    });
    await setLoading(loading);
  };

  const onCompanySubmit = async () => {
    try {
      setIsSubmittingModal(true);
      if (!validateForm(modalFormValues, validatorValuesCompany)) {
        await setErrorMessage(
          "Complete todos los datos obligatorios antes de continuar."
        );
        await setShowToast(true);
        return;
      }
      modalFormValues.organization_id =
        modalFormValues.organization_id === ""
          ? null
          : modalFormValues.organization_id;

      modalFormValues.street_number = !modalFormValues.street_number
        ? null
        : modalFormValues.street_number;

      let response;
      let update = false;

      if (createModeModal) {
        response = await apiPost({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.COMPANIES.CREATE}`,
          body: formatCompanyValues(modalFormValues),
          getRepresentation: true,
        });
      } else {
        update = true;
        response = await apiPatch({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.COMPANIES.UPDATE}${modalFormValues.id}`,
          body: formatCompanyValues(modalFormValues),
        });
      }

      if (response && response.length > 0 && response[0].id) {
        await setShowToast(true);
        await setSuccessMessage(
          update
            ? `La compañía #${response[0].id} se actualizo de forma correcta`
            : `La compañía #${response[0].id} fue creado de forma correcta${
                modalFormValues.organization_id
                  ? ` y vinculado a la organizacion #${modalFormValues.organization_id}`
                  : ""
              }.`
        );
        await closeModal(true);
      }
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    } finally {
      setIsSubmittingModal(false);
    }
  };

  const onOrganizationSubmit = async (e: any) => {
    try {
      if (!validateForm(formValues, validatorValues)) {
        throw new Error(
          "Complete todos los datos obligatorios antes de continuar."
        );
      }
      const formatBody: any = formatOrganizationValues(formValues);

      let response: any;
      let update = false;

      if (createMode) {
        response = await apiPost({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ORGANIZATIONS.CREATE}`,
          body: formatBody,
          getRepresentation: true,
        });
      } else {
        if (record && record.domicilio && record.domicilio.id) {
          formatBody.domicilio = {
            id: record.domicilio.id,
            ...formatBody.domicilio,
          };
        }
        update = true;
        response = await apiPatch({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ORGANIZATIONS.UPDATE}${record.id}`,
          body: formatBody,
        });
      }

      if (toSaveAfterOrg && toSaveAfterOrg) {
        toSaveAfterOrg.map(async (d: { method: string; body: any }) => {
          d.body.organization_id = response.id;
          if (d.method === "linkDevice") {
            await dispatch(linkOrUnlinkOrganizationAction(d.body));
            await getUnlinkDeviceList();
          } else {
            await apiPatch({
              url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.UPDATE}${d.body.id}`,
              body: d.body,
            });
          }
        });
      }

      const message = await prepareToastData(
        response,
        "Organización",
        "id",
        "La",
        formatBody.legal_name,
        update
      );

      navigate(Paths.ORGANIZATION, {
        state: {
          message: message,
        },
      });
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    if (errorMessage) {
      await setErrorMessage(undefined);
    }
    if (successMessage) {
      await setSuccessMessage(undefined);
    }
  };

  const onUserSubmit = async () => {
    try {
      setIsSubmittingModal(true);
      if (!validateForm(modalFormValues, validatorValuesUser)) {
        await setErrorMessage(
          "Complete todos los datos obligatorios antes de continuar."
        );
        await setShowToast(true);
        return;
      }
      const userId = modalFormValues.id;

      let bodyContent: any = {
        role: "iof_superadmin",
        email: modalFormValues.email,
        password: "123123",
        email_confirm: true,
        user_metadata: {
          role_id: formValues.role_id,
          fullname: modalFormValues.fullname,
          username: modalFormValues.email,
          organization_id:
            modalFormValues.organization_id === ""
              ? null
              : modalFormValues.organization_id,
          national_id: formValues.national_id,
          phone: formValues.phone,
        },
      };

      let response;
      if (createModeModal) {
        response = await apiPost({
          url: `${API_URL_AUTH}/${Endpoints.AUTH.SIGNUP}`,
          body: bodyContent,
        });
        if (response) {
          response = await apiGet({
            url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.USERS.GET_BY_EMAIL}${bodyContent.email}`,
          });
        }
      } else {
        await apiPut({
          url: `${API_URL_AUTH}/${Endpoints.AUTH.SIGNUP}/${userId}`,
          body: bodyContent,
        });
      }
      if (response) {
        await setShowToast(true);
        typeof response === "string"
          ? await setErrorMessage(response)
          : await setSuccessMessage(
              `El Usuario #${
                response ? response[0].id : userId
              } fue creado de forma correcta${
                modalFormValues.organization_id
                  ? ` y vinculado a la organizacion #${modalFormValues.organization_id}`
                  : ""
              }.`
            );
        await closeModal(true);
      }
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    } finally {
      setIsSubmittingModal(false);
    }
  };

  const onCountrysidesSubmit = async () => {
    try {
      setIsSubmittingModal(true);
      if (!validateForm(modalFormValues, validatorValuesCountrysides)) {
        await setErrorMessage(
          "Complete todos los datos obligatorios antes de continuar."
        );
        await setShowToast(true);
        return;
      }
      const formatBody: any = formatCountrysidesValues(
        modalFormValues,
        organizationsData.localities
      );

      formatBody.organizacion_id =
        formatBody.organizacion_id === "" ? null : formatBody.organizacion_id;

      let response;
      let update = false;
      if (createModeModal) {
        response = await apiPost({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.COUNTRYSIDES.CREATE}`,
          body: formatBody,
          getRepresentation: true,
        });
      } else {
        update = true;
        response = await apiPatch({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.COUNTRYSIDES.UPDATE}${modalFormValues.id}`,
          body: formatBody,
        });
      }

      if (response && response.length > 0 && response[0].id) {
        await setShowToast(true);
        await setSuccessMessage(
          update
            ? `El establecimiento #${response[0].id} se actualizo de forma correcta`
            : `El establecimiento #${
                response[0].id
              } fue creado de forma correcta${
                modalFormValues.organization_id
                  ? ` y vinculado a la organizacion #${modalFormValues.organization_id}`
                  : ""
              }.`
        );
        await closeModal(true);
      }
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    } finally {
      setIsSubmittingModal(false);
    }
  };

  const handleModalSubmit = async () => {
    const name = modalProps.name;
    switch (name) {
      case "device":
        submitDevice();
        break;
      case "user":
        if (!viewModeModal) onUserSubmit();
        if (viewModeModal) setToEditMode("user", "edit");
        break;
      case "company":
        if (!viewModeModal) onCompanySubmit();
        if (viewModeModal) setToEditMode("company", "edit");
        break;
      case "countrysides":
        if (!viewModeModal) onCountrysidesSubmit();
        if (viewModeModal) setToEditMode("countrysides", "edit");
        break;
    }
  };

  const setToEditMode = (
    name: "company" | "device" | "countrysides" | "user",
    mode: "create" | "edit" | "view" | "link"
  ) => {
    setViewModeModal(false);
    setLinkModeModal(false);
    setEditionModeModal(true);
    const titleResponse = getOrganizationTitle(name, mode);
    setModalProps({
      ...modalProps,
      title: titleResponse.mainTitle,
      submitName: titleResponse.submitButton,
    });
  };

  const submitDevice = async () => {
    if (linkModeModal) {
      linkDevice();
    } else if (viewModeModal) {
      setToEditMode("device", "edit");
    } else {
      createDevice();
    }
  };

  const linkDevice = async () => {
    if (
      !selectedFromOrgRows ||
      (selectedFromOrgRows && selectedFromOrgRows.length === 0)
    ) {
      await setErrorMessage(
        `No se selecciono ningun dispositivo para vincular`
      );
      await setShowToast(true);
      return;
    }
    const body = {
      organization_id: record && record.id ? record.id : null,
      devices_id: "",
    };

    // eslint-disable-next-line array-callback-return
    selectedFromOrgRows.map((s) => {
      if (body.devices_id === "") {
        body.devices_id = s;
      } else {
        body.devices_id = `${body.devices_id},${s}`;
      }
    });

    if (body.organization_id) {
      await dispatch(linkOrUnlinkOrganizationAction(body));
      await getUnlinkDeviceList();
    } else {
      let toSave = cloneWithoutReference(toSaveAfterOrg);
      const index = findIndex(toSave, "method", "linkDevice");
      if (index !== -1) {
        toSave[index].body = body;
      } else {
        toSave = [
          {
            method: "linkDevice",
            body: body,
          },
        ];
      }
      setToSaveAfterOrg(toSave);
    }
    await closeModal(true);
  };

  const createDevice = async () => {
    try {
      setIsSubmittingModal(true);
      if (!validateForm(modalFormValues, validatorDeviceValue)) {
        throw new Error(
          "Complete todos los datos obligatorios antes de continuar."
        );
      }

      modalFormValues.organization_id = record && record.id;
      modalFormValues.device_status_id = modalFormValues.device_status_id
        ? 1
        : 2;

      const response = await apiPost({
        url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.DEVICES.CREATE}`,
        body: modalFormValues,
      });
      if (response && response.id) {
        await setShowToast(true);
        await setSuccessMessage(
          `El Dispositivo #${response.id} fue creado de forma correcta${
            record && record.nombre
              ? ` y vinculado a la organizacion #${record.nombre}.`
              : `.`
          }`
        );
      }

      if (!modalFormValues.organization_id) {
        let toSave = cloneWithoutReference(toSaveAfterOrg);
        toSave.push({
          method: "updateDevice",
          body: response,
        });
        setToSaveAfterOrg(toSave);
      }
      await closeModal(true);
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    } finally {
      setIsSubmittingModal(false);
    }
  };

  const handleModalOpen = async (
    name: "company" | "device" | "countrysides" | "user",
    mode: "create" | "edit" | "view" | "link",
    initialValues?: any
  ) => {
    let formValues: any = {};
    if (!initialValues) {
      switch (name) {
        case "company":
          formValues = companyFormValues;
          break;
        case "device":
          formValues = newDeviceFormValues;
          break;
        case "countrysides":
          formValues = newCountrysidesFormValues;
          break;
        case "user":
          formValues = newUserFormValues;
          break;
      }
    } else {
      formValues = formattedInitialValues(name, initialValues);
    }

    if (name === "company" || name === "countrysides") {
      formValues = findCity(formValues, organizationsData, name);
      formValues.organization_id = record && record.id ? record.id : null;
    } else {
      formValues.organization_id = record && record.id ? record.id : null;
    }

    switch (mode) {
      case "create":
        setEditionModeModal(true);
        setCreateModeModal(true);
        break;
      case "edit":
        setEditionModeModal(true);
        break;
      case "view":
        setViewModeModal(true);
        break;
      case "link":
        setLinkModeModal(true);
        break;
      default:
        break;
    }
    await setModalFormValues(formValues);
    const responseTitle = getOrganizationTitle(name, mode);
    setModalProps({
      open: true,
      name,
      title: responseTitle.mainTitle,
      submitName: responseTitle.submitButton,
    });
  };

  const closeModal = async (refresh = false) => {
    setModalProps({
      open: false,
      name: undefined,
      formValues: undefined,
      onSubmit: undefined,
      title: undefined,
    });
    setViewModeModal(false);
    setEditionModeModal(false);
    setCreateModeModal(false);
    setLinkModeModal(false);
    setSelectedFromOrgRows([]);
    if (refresh) {
      await getAllNewOrganizationData();
    }
  };

  const onModalChange = ({ value, name }: any) => {
    setModalFormValues({ ...modalFormValues, [name]: value });
  };

  const onChange = ({ value, name }: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const header = (
    <div className="grid-actions justify-between flexbox">
      <h3>Datos generales</h3>
      {!createMode && !localEditMode && (
        <Button
          className="login-button ligth-green "
          style={{ width: "20%" }}
          variant="contained"
          onClick={(e) => {
            if (localEditMode || createMode) {
              onOrganizationSubmit(e);
            } else {
              setLocalEditMode(true);
            }
          }}
          type="submit"
        >
          {editionMode || localEditMode ? "Guardar" : "Editar"}
        </Button>
      )}
    </div>
  );
  console.log("!loading = " + !loading);
  console.log(
    "(!modalProps || !modalProps.open) = " + (!modalProps || !modalProps.open)
  );
  console.log(
    "((record && formValues.municipality_id !== undefined) || !record) = " +
      ((record && formValues.municipality_id !== undefined) || !record)
  );

  console.log(
    !loading &&
      (!modalProps || !modalProps.open) &&
      ((record && formValues.municipality_id !== undefined) || !record)
  );

  return (
    <>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={errorMessage ? true : false}
        bodyContent={errorMessage || successMessage}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Grid container rowGap={1}>
            {!createMode && !localEditMode && (
              <Grid item xs={2} className="organization-header">
                <Button
                  className="login-button text-button"
                  style={{ marginLeft: -10 }}
                  variant="text"
                  color="info"
                  onClick={() => navigate(Paths.ORGANIZATION)}
                >
                  Cancelar
                </Button>
              </Grid>
            )}
            <LayoutGridBoxComponent className="white" header={header}>
              <>
                {!loading &&
                  (!modalProps || !modalProps.open) &&
                  ((record && formValues.municipality_id !== undefined) ||
                    !record) && (
                    <OrganizationFormGridComponent
                      editionMode={editionMode || createMode || localEditMode}
                      formValues={formValues}
                      onChange={onChange}
                      onSubmit={onOrganizationSubmit}
                    />
                  )}
              </>
            </LayoutGridBoxComponent>
            <Grid item xs={12}>
              <Grid
                container
                className="white"
                style={{ padding: 17 }}
                rowGap={1}
              >
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={8}>
                      <h3 className="w-500" style={{ wordBreak: "break-all" }}>
                        Compañías
                      </h3>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomButton
                        key={"COMPANIA-BUTTON"}
                        id={"COMPANIA-BUTTON"}
                        label={"compañía".toLocaleUpperCase()}
                        type="SIMPLE"
                        onClick={() => handleModalOpen("company", "create")}
                        startAdornment
                        style={{ float: "right" }}
                        disabled={!record || (record && !record.id)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  {organizationsData.companiesList &&
                  organizationsData.companiesList.length > 0 ? (
                    <GenericGrid
                      style={{ padding: 0 }}
                      columns={companiesColumns}
                      displayData={organizationsData.companiesList}
                      rowsPerPage={5}
                      onActionClick={(action: any, record) =>
                        handleModalOpen("company", action.toLowerCase(), record)
                      }
                      actions={{
                        view: true,
                        delete: false,
                        edit: true,
                      }}
                    />
                  ) : (
                    "Si el cliente no administra empresas, simplemente pulse guardar. Caso contrario, agregue cuantas empresas requiera."
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12} className="grid-header">
              <LayoutGridBoxComponent>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item xs={4}>
                    <Button
                      className="login-button text-button"
                      style={{ marginLeft: -10 }}
                      variant="text"
                      color="info"
                      onClick={() => navigate(Paths.ORGANIZATION)}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  {(createMode || localEditMode) && (
                    <Grid item xs={4}>
                      <Button
                        className="login-button ligth-green "
                        style={{ margin: "auto" }}
                        variant="contained"
                        onClick={(e) => {
                          if (localEditMode || createMode) {
                            onOrganizationSubmit(e);
                          } else {
                            setLocalEditMode(true);
                          }
                        }}
                        type="submit"
                      >
                        {editionMode || localEditMode ? "Guardar" : "Editar"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </LayoutGridBoxComponent>
            </Grid>
            <RightColumn
              usersList={organizationsData.usersList}
              companiesList={organizationsData.companiesList}
              devicesList={organizationsData.devicesList}
              countrysidesList={organizationsData.countrysidesList}
              handleModalOpen={handleModalOpen}
              disabledButtons={!record || (record && !record.id)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Modal
        onSubmit={handleModalSubmit}
        open={modalProps.open}
        title={modalProps.title || ""}
        submitName={modalProps.submitName || ""}
        id="custom-modal"
        handleClose={closeModal}
        isSubmitting={isSubmittingModal}
      >
        <ModalFormHandler
          name={modalProps.name}
          organizationId={(record && record.id) || null}
          formValues={modalFormValues}
          onChange={onModalChange}
          editionMode={editionModeModal}
          createMode={createModeModal}
          viewMode={viewModeModal}
          selectedFromOrgRows={selectedFromOrgRows}
          setSelectedFromOrgRows={setSelectedFromOrgRows}
        />
      </Modal>
    </>
  );
};

export default NewOrganizationComponent;
