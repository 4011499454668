export const isCRUDdisabled = (
  usersList: any[],
  permissionsList: any[],
  username: string,
  screen: any
) => {
  if (
    !usersList ||
    usersList.length === 0 ||
    !permissionsList ||
    permissionsList.length === 0
  )
    return false;
  const user = usersList.find((val) => val.username === username);
  const permissions =
    user &&
    user.user_permissions
      .filter((val: any) => val.deshabilitado === 0)
      .map((val: any) => {
        return val.permisoId;
      });
  const userPermissions = permissionsList.filter((val) => {
    return permissions && permissions.find((elem: any) => elem === val.id);
  });
  return true;
};
