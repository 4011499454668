import { formatDate } from "../../../shared/helper";

export const Columns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "created_at",
    label: "F.Creacion",
    applyFormat: true,
    format: (value: number) => formatDate(value),
  },
  { id: "name", label: "Nombre" },
  { id: "acciones", label: "Acciones" },
];
