import React from "react";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";
import { VerifyAccountComponent } from "../../components/verify";

const VerifySignupPage = (props: any) => {
  return (
    <AuthenticatedRoute withHeader path={Paths.VERIFYACCOUNT}>
      <VerifyAccountComponent verifyType={"signup"} buttonLabel={"Verificar"} />
    </AuthenticatedRoute>
  );
};

export default VerifySignupPage;
