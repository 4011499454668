import React, { useEffect, useState } from "react";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastComponent } from "../../components/toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReduxType } from "../../shared/types";
import { apiPost } from "../../shared/ApiService";
import { API_URL_AUTH } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";

const RecoverPage = (props: any) => {
  const [userEmail, serUserEmail] = useState<string>();
  const [validated, setValidated] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const navigate = useNavigate();

  const { userData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (userData.error.message) {
      showErrors(userData.error.message);
    }
  }, [userData.error.message]);

  const handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false || !userEmail) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      doVerifyRequest();
    }
  };

  const doVerifyRequest = async () => {
    try {
      const response = await apiPost({
        url: `${API_URL_AUTH}/${Endpoints.AUTH.RECOVER}`,
        body: { email: userEmail },
        getRepresentation: true,
      });
      if (response) {
        navigate(Paths.VERIFYRESETACCOUNT);
      }
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const showErrors = async (error: string) => {
    await setErrorMessage(error);
    await setShowToast(true);
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  return (
    <AuthenticatedRoute withHeader path={Paths.RECOVER}>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} style={{ marginTop: 24 }}>
          <span className="w-500">Recuperar contraseña</span>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="standard-required"
            label="Email"
            type="email"
            variant="standard"
            margin="normal"
            error={userEmail === "" || (userEmail === undefined && !validated)}
            onChange={(e) => serUserEmail(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            className="login-button ligth-green"
            variant="contained"
            onClick={handleSubmit}
          >
            Ingresar
          </Button>
        </Grid>
      </Grid>
    </AuthenticatedRoute>
  );
};

export default RecoverPage;
