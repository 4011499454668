import * as types from "./actionTypes";

const initialState = {
  device: undefined,
  devicesList: undefined,
  devicesUnlink: undefined,
  states: undefined,
  networks: undefined,
  types: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const DeviceReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.CREATE_DEVICE_ACTION:
    case types.FIND_DEVICE_STATES:
    case types.FIND_DEVICE_TYPES:
    case types.FIND_DEVICE_NETWORKS:
    case types.GET_DEVICES_LIST_DATA:
    case types.GET_DEVICES_UNLINK_LIST_DATA:
    case types.LINK_OR_UNLINK_ORGANIZATION:
    case types.UPDATE_DEVICE:
      state = { ...state, loading: true };
      break;

    case types.GET_DEVICES_LIST_DATA_SUCCESS:
      state = { ...state, devicesList: actionPayload, loading: false };
      break;

    case types.LINK_OR_UNLINK_ORGANIZATION_SUCCESS:
      state = { ...state, loading: false };
      break;

    case types.CREATE_DEVICE_SUCCESS:
    case types.UPDATE_DEVICE_SUCCESS:
      state = { ...state, device: actionPayload, loading: false };
      break;

    case types.GET_DEVICES_UNLINK_LIST_DATA_SUCCESS:
      state = { ...state, devicesUnlink: actionPayload, loading: false };

      break;
    case types.FIND_DEVICE_STATES_SUCCESS:
      state = {
        ...state,
        states: actionPayload,
        loading: false,
      };
      break;

    case types.FIND_DEVICE_TYPES_SUCCESS:
      state = {
        ...state,
        types: actionPayload,
        loading: false,
      };
      break;

    case types.FIND_DEVICE_NETWORKS_SUCCESS:
      state = {
        ...state,
        networks: actionPayload,
        loading: false,
      };
      break;

    case types.CREATE_DEVICE_FAIL:
    case types.FIND_DEVICE_STATES_FAIL:
    case types.FIND_DEVICE_TYPES_FAIL:
    case types.FIND_DEVICE_NETWORKS_FAIL:
    case types.GET_DEVICES_LIST_DATA_FAIL:
    case types.GET_DEVICES_UNLINK_LIST_DATA_FAIL:
    case types.LINK_OR_UNLINK_ORGANIZATION_FAIL:
    case types.UPDATE_DEVICE_FAIL:
      state = {
        ...state,
        error: {
          message: actionPayload,
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default DeviceReducer;
