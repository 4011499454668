import React, { useRef } from "react";

import DELETE from "../../assets/icons/svg/GridActions/delete.svg";
import CONFIRM from "../../assets/icons/svg/GridActions/confirm.svg";
import _ from "lodash";

export const ConfirmationDeleteIcon = (props: any) => {
  const [confirmation, setConfirmation] = React.useState(false);
  const confirmationREF = useRef<any>(null);

  const { onClick, id } = props;

  const onButtonClick = (event: any, isFromButton?: boolean) => {
    if (isFromButton) {
      if (confirmation) {
        onClick();
      } else {
        setConfirmation(true);
      }
    } else if (
      confirmationREF.current &&
      !_.isEqual(confirmationREF.current, event.target)
    ) {
      setConfirmation(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", onButtonClick);

    return () => {
      document.removeEventListener("mousedown", onButtonClick);
    };
  }, []);

  return (
    <img
      onClick={(e) => onButtonClick(e, true)}
      ref={confirmationREF}
      src={!confirmation ? DELETE : CONFIRM}
      alt="delete-icon"
      title="Delete"
      id={`DELETE-${id}`}
      key={id}
    />
  );
};
