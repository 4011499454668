import { call, put, takeLatest } from "redux-saga/effects";
import { Endpoints } from "../../config/endpoints";
import { API_WOZALABS_URL } from "../../config/general-config";
import { apiDelete, apiGet } from "../../shared/ApiService";
import { ApiResponse } from "../../shared/types";
import {
  getPermissionsListSuccess,
  getPermissionsListFail,
  getRolesListFail,
  getRolesListSuccess,
  deleteRolSuccess,
  deleteRolFail,
} from "./actions";
import {
  DELETE_ROL,
  GET_PERMISSIONS_LIST,
  GET_ROLES_LIST,
} from "./actionTypes";

function* onGetPermissionsList() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.PERMISSIONS.GET}`,
        })
      );
    });
    yield put(getPermissionsListSuccess(response));
  } catch (error) {
    yield put(getPermissionsListFail(error));
  }
}

function* onGetRolesList() {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ROLES.GET}`,
        })
      );
    });
    yield put(getRolesListSuccess(response));
  } catch (error) {
    yield put(getRolesListFail(error));
  }
}

function* onDeleteRol(action: any) {
  try {
    const response: ApiResponse = yield call(() => {
      return Promise.resolve(
        apiDelete({
          url: `${API_WOZALABS_URL}/${Endpoints.WOZALABS.ROLES.DELETE}${action.rolId}`,
        })
      );
    });
    yield put(deleteRolSuccess(response));
  } catch (error) {
    yield put(deleteRolFail(error));
  }
}

function* RolesSaga() {
  yield takeLatest(GET_ROLES_LIST, onGetRolesList);
  yield takeLatest(GET_PERMISSIONS_LIST, onGetPermissionsList);
  yield takeLatest(DELETE_ROL, onDeleteRol);
}

export default RolesSaga;
