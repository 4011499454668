import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  paperClasses,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import {
  alphanumericRegex,
  dniRegex,
  emailRegex,
} from "../../../../shared/regexPatterns";
import { CustomTextField } from "../../../formInputs";
import { StyledSelect } from "../../../formInputs/SelectComponent/StyledSelect";
import { GridActions } from "../../gridActions";

export interface FormGridProps {
  rolesList: any[];
  organizations: any[];
  createMode: boolean;
  editionMode?: boolean;
  onChange: ({ value, name }: { value: any; name: string }) => void;
  handlePermissionDelete: (id: number) => Promise<void>;
  formValues: any;
  permissionsList: any[];
  organizationId?: any;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${paperClasses.rounded}`]: {
    boxShadow: "none",
    border: "1px solid rgba(224, 224, 224, 1)",
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    lineHeight: "unset",
  },
}));

const columns: any = [
  { id: "name", label: "Permisos", minWidth: 500 },
  { id: "acciones", label: "Acciones", minWidth: 50, align: "center" },
];

const FormGridComponent = (props: FormGridProps) => {
  const {
    onChange,
    handlePermissionDelete,
    formValues,
    editionMode,
    rolesList,
    organizations,
    organizationId,
    createMode,
    permissionsList,
  } = props;

  return (
    <form>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="provincia-select-label">Organización</InputLabel>
            <StyledSelect
              labelId="provincia-select-label"
              id="organization_id"
              name="organization_id"
              defaultValue={
                formValues.organization_id !== null
                  ? formValues.organization_id
                  : ""
              }
              onChange={(e) =>
                onChange({ value: e.target.value, name: "organization_id" })
              }
              disabled={!editionMode || !!organizationId}
            >
              {organizations &&
                organizations.map((o, index: number) => {
                  return (
                    <MenuItem key={`index-${index}`} value={o.id}>
                      {o.name}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            id="fullname"
            name="fullname"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.fullname}
            onChange={onChange}
            required
            label="Nombre y Apellido"
            pattern={alphanumericRegex}
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            id="email"
            name="email"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.email}
            onChange={onChange}
            pattern={emailRegex}
            label="Email"
            required
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTextField
            id="national_id"
            name="national_id"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.national_id}
            pattern={dniRegex}
            onChange={onChange}
            label="DNI"
            disabled={!editionMode}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <CustomTextField
            id="phone"
            name="phone"
            variant="filled"
            style={{ width: "100%" }}
            defaultValue={formValues.phone}
            onChange={onChange}
            pattern={alphanumericRegex}
            label="Teléfono"
            disabled={!editionMode}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="tipo-select-label">Rol *</InputLabel>
            <StyledSelect
              labelId="tipo-select-label"
              id="rolId"
              name="rolId"
              defaultValue={
                formValues.role_id !== null ? formValues.role_id : ""
              }
              required
              onChange={(e) =>
                onChange({ value: e.target.value, name: "role_id" })
              }
              disabled={!editionMode}
            >
              {rolesList &&
                rolesList.map((t, index: number) => {
                  return (
                    <MenuItem key={`index-${index}`} value={t.id}>
                      {t.name}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues.active}
                disabled={!editionMode}
                onChange={(e) =>
                  onChange({ value: !formValues.active, name: "active" })
                }
              />
            }
            label="Activo"
            labelPlacement="start"
          />
        </Grid>
        {!createMode && (
          <Grid item xs={12}>
            <StyledPaper
              className="mt-2"
              sx={{ width: "100%", overflow: "hidden" }}
            >
              <TableContainer style={{ maxHeight: 532 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns &&
                        columns.map((column: any) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissionsList &&
                      permissionsList.length > 0 &&
                      permissionsList.map((row: any) => {
                        const user_permission =
                          formValues.user_permissions.find(
                            (value: any) => value.permission_id === row.id
                          );
                        let finalRow = { ...row, active: false };
                        if (user_permission)
                          finalRow = {
                            ...user_permission,
                            name: user_permission.permissions.name,
                          };
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={finalRow.id}
                          >
                            {columns &&
                              columns.map((column: any) => {
                                const value = !finalRow[column.id]
                                  ? "-"
                                  : finalRow[column.id];
                                if (column.render) {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.render(value, finalRow)}
                                    </TableCell>
                                  );
                                }
                                switch (column.id) {
                                  case "acciones":
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        <GridActions
                                          data={finalRow}
                                          align={column.align}
                                          actions={
                                            !editionMode
                                              ? {
                                                  view: false,
                                                  delete: false,
                                                  edit: false,
                                                  toggle: false,
                                                }
                                              : {
                                                  view: false,
                                                  delete: false,
                                                  edit: false,
                                                  toggle: { name: "active" },
                                                }
                                          }
                                          onClick={() =>
                                            handlePermissionDelete(finalRow)
                                          }
                                          id={finalRow.id}
                                          key={finalRow.id}
                                        />
                                      </TableCell>
                                    );
                                  default:
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        {finalRow.name}
                                      </TableCell>
                                    );
                                }
                              })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledPaper>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default FormGridComponent;
