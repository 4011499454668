import { CSSProperties } from "react";
import { formatDate } from "../../../shared/helper";
import { DeviceColumnProps } from "../../../shared/types";

const state = {
  id: "estado",
  label: "Estado",
  minWidth: 30,
  render: (value: any, record: any) => {
    const activeStyle: CSSProperties = {
      padding: 5,
      background: "rgba(139, 195, 74, 0.2)",
      borderRadius: 3,
      textAlign: "center",
      color: "#6BAA00",
    };
    const inactiveStyle: CSSProperties = {
      color: "#C3604A",
      background: "rgba(195, 96, 74, 0.2)",
      textAlign: "center",
      padding: 5,
      borderRadius: 3,
    };
    return (
      <div
        style={
          record.device_statuses.name === "Deshabilitado"
            ? inactiveStyle
            : activeStyle
        }
      >
        {record.device_statuses.name === "Deshabilitado" ? "Broken" : "Ok"}
      </div>
    );
  },
};

export const organizationDeviceModalColumns: readonly any[] = [
  {
    id: "checkbox",
    label: "",
    idValue: "id",
  },
  { id: "id", label: "ID" },
  {
    id: "tipo",
    label: "Tipo",
    render: (value: any, record: any) => {
      return <>{record.device_types.name}</>;
    },
  },
  {
    id: "name",
    label: "Nombre",
  },
  {
    id: "eui_id",
    label: "EUI",
  },
  { ...state },
];

export const defaultColumns: readonly DeviceColumnProps[] = [
  {
    id: "checkbox",
    label: "",
    idValue: "id",
    width: 50,
  },
  { id: "id", label: "ID", width: 50 },
  {
    id: "name",
    label: "Nombre",
    width: 125,
  },
  {
    id: "type",
    label: "Tipo",
    render: (value: any, record: any) => {
      return <div style={{ width: 115 }}>{record.device_types.name}</div>;
    },
  },
  {
    id: "lance_number",
    width: 100,
    label: "Número de lanza",
  },
  {
    id: "eui_id",
    width: 150,
    label: "EUI",
  },
  {
    id: "version",
    width: 80,
    label: "Version",
  },
  {
    id: "organization_id",
    width: 80,
    label: "Organizacion",
    showOrgName: true,
  },
  {
    id: "device_statuses",
    label: "Estado",
    render: (value: any, record: any) => {
      const activeStyle: CSSProperties = {
        padding: 5,
        background: "rgba(139, 195, 74, 0.2)",
        borderRadius: 3,
        textAlign: "center",
        color: "#6BAA00",
      };
      const inactiveStyle: CSSProperties = {
        color: "#C3604A",
        background: "rgba(195, 96, 74, 0.2)",
        textAlign: "center",
        padding: 5,
        borderRadius: 3,
      };
      return (
        <div
          style={
            record.device_statuses.name === "Deshabilitado"
              ? inactiveStyle
              : activeStyle
          }
        >
          {record.device_statuses.name === "Deshabilitado" ? "Broken" : "Ok"}
        </div>
      );
    },
  },
  {
    id: "network_server",
    label: "Network Server",
    align: "center",
    render: (value: any, record: any) => {
      return <span style={{ width: 100 }}>{record.device_net_types.name}</span>;
    },
  },
  {
    id: "created_at",
    label: "F.Creación",
    width: 100,
    applyFormat: true,
    format: (value: number) => formatDate(value),
  },
  {
    id: "acciones",
    label: "Acciones",
    width: 70,
  },
];
