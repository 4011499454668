export const Endpoints = {
  AUTH: {
    LOGIN: "token?grant_type=password",
    LOGIN_REFRESH: "token?grant_type=refresh_token",
    SIGNUP: "admin/users",
    SEND_EMAIL: "otp",
    VERIFY: "verify",
    RECOVER: "recover",
    USERS: {
      PUT: "user",
    },
  },
  PROFILE: "start",
  DASHBOARD: "dashboard_backoffice_data",
  USERS: {
    GET_LIST: "users",
    CREATE: "api/users",
    UPDATE: "api/users",
  },
  HARVESTS: "harvests",
  COMPANIES: "companies",
  ESTABLISHMENTS: "establishments",
  ORGANIZATIONS: {
    GET_LIST: "organization",
    GET: "organization/getOrganizationData",
    CREATE: "api/organization",
    UPDATE: "api/organization",
  },
  ROLES: {
    GET_LIST: "roles",
    GET_PERMISSIONS_LIST: "permissions",
    PUT_PERMISSION: "api/users/userPermission",
  },
  HELPERS: {
    LOCALITY: "locality",
    CITY: "city",
    PROVINCE: "province",
  },
  DEVICES: {
    GET_LIST: "device",
    GET_UNLINK_LIST: "device/unlinkDevices",
    CREATE: "api/device",
    UPDATE: "api/device",
    LINK_UNLINK: "api/device/linkOrUnlink",
    FIND_BY_TYPE: "api/device/findByType",
  },
  WOZALABS: {
    COMPANIES: {
      GET: "companies?select=*,organizations(*),countrysides(*)&&organization_id=eq.",
      CREATE: "companies",
      UPDATE: "companies?id=eq.",
    },
    PERMISSIONS: { GET: "permissions?active=eq.true" },
    COUNTRYSIDES: {
      CREATE: "countrysides",
      UPDATE: "countrysides?id=eq.",
    },
    DEVICES: {
      GET: "devices?active=eq.true&&select=*,device_types(id,name),device_net_types(id,name),device_statuses(id,name)&&order=id.desc&&active=eq.true",
      GET_STATES: "device_statuses",
      GET_NETWORKS: "device_net_types",
      GET_TYPES: "device_types",
      GET_BY_ORG:
        "devices?select=*,device_types(id,name),device_statuses(id,name)&&organization_id=eq.",
      GET_UNLINK_LIST:
        "devices?active=eq.true&&organization_id=is.null&&select=*,device_types(id,name),device_net_types(id,name),device_statuses(id,name)",
      CREATE: "devices",
      UPDATE: "devices?id=eq.",
      LINK_OR_UNLINK: "devices?id=in.",
    },

    HARVESTS: {
      GET: "silobag_harvests?active=eq.true",
      CREATE: "silobag_harvests",
      UPDATE: "silobag_harvests?id=eq.",
    },
    HELPERS: {
      LOCALITY: "localities",
      CITY: "municipalities",
    },
    ORGANIZATIONS: {
      GET: "organizations?active=eq.true",
      GET_ORGANIZATION_DATA:
        "organizations?select=*,companies(*,countrysides(*,lots(*))),devices!organization_id(id,name,device_types(id,name),device_statuses(id,name),device_net_types(id,name)),users!users_organizations_fkey(*,roles(id,name),user_permissions(*,permissions(*)))&id=eq.",
      CREATE: "organizations",
      UPDATE: "organizations?id=eq.",
      DELETE: "organizations?id=eq.",
    },
    USERS: {
      GET: "users?select=*,organizations!users_organizations_fkey(id,name),roles(id,name),user_permissions(*,permissions(*))&&active=eq.true",
      GET_BY_EMAIL: "users?email=eq.",
      POST: "users?select=*,roles(*, permissions(*))",
      PATCH: "users?id=eq.",
      PERMISSIONS_POST: "user_permissions",
      PERMISSIONS_PATCH: "user_permissions?id=eq.",
    },
    ROLES: {
      GET: "roles?select=*,permissions(active,id,name,role_permissions(*))&&active=eq.true",
      ROLE_PERMISSIONS: "role_permissions",
      ROLE_PERMISSIONS_IN: "role_permissions?id=in.(",
      DELETE: "roles?id=eq.",
      POST: "roles",
    },
  },
};
