import * as types from "./actionTypes";

export const getOrganizationsListDataAction = () => {
  return {
    type: types.GET_ORGANIZATIONS_LIST_DATA,
  };
};

export const getOrganizationsListDataFail = (error: any) => {
  return { type: types.GET_ORGANIZATIONS_LIST_DATA_FAIL, payload: error };
};

export const getOrganizationsListDataSuccess = (dashboardList: any) => {
  return {
    type: types.GET_ORGANIZATIONS_LIST_DATA_SUCCESS,
    payload: dashboardList,
  };
};

export const getProvincesListDataAction = (cleanData: boolean) => {
  return {
    type: types.GET_PROVINCE,
    cleanData,
  };
};

export const getProvincesListDataFail = (error: any) => {
  return { type: types.GET_PROVINCE_FAIL, payload: error };
};

export const getProvincesListDataSuccess = (provincesList: any) => {
  return {
    type: types.GET_PROVINCE_SUCCESS,
    payload: provincesList,
  };
};

export const getCitiesListDataAction = (
  provinceId: number | null,
  cleanData: boolean
) => {
  return {
    type: types.GET_CITY,
    provinceId,
    cleanData,
  };
};

export const getCitiesListDataFail = (error: any) => {
  return { type: types.GET_CITY_FAIL, payload: error };
};

export const getCitiesListDataSuccess = (citiesList: any) => {
  return {
    type: types.GET_CITY_SUCCESS,
    payload: citiesList,
  };
};

export const getLocalitiesListDataAction = (cityId: string | null) => {
  return {
    type: types.GET_LOCALITY,
    cityId,
  };
};

export const getLocalitiesListDataFail = (error: any) => {
  return { type: types.GET_LOCALITY_FAIL, payload: error };
};

export const getLocalitiesListDataSuccess = (localitiesList: any) => {
  return {
    type: types.GET_LOCALITY_SUCCESS,
    payload: localitiesList,
  };
};

export const createOrganizationAction = (payload: any) => {
  return {
    type: types.CREATE_ORGANIZATION,
    payload,
  };
};

export const createOrganizationFail = (error: any) => {
  return {
    type: types.CREATE_ORGANIZATION_FAIL,
    payload: error,
  };
};

export const createOrganizationSuccess = (response: any) => {
  return {
    type: types.CREATE_ORGANIZATION_SUCCESS,
    payload: response,
  };
};

export const updateOrganizationDataAction = (
  organizationId: number,
  payload: any
) => {
  return {
    type: types.UPDATE_ORGANIZATION,
    organizationId,
    payload,
  };
};

export const updateOrganizationDataFail = (error: any) => {
  return { type: types.UPDATE_ORGANIZATION_FAIL, payload: error };
};

export const updateOrganizationDataSuccess = (dashboardList: any) => {
  return {
    type: types.UPDATE_ORGANIZATION_SUCCESS,
    payload: dashboardList,
  };
};

export const deleteOrganizationAction = (organizationId: number) => {
  return { type: types.DELETE_ORGANIZATION, organizationId };
};

export const deleteOrganizationFail = (error: any) => {
  return { type: types.DELETE_ORGANIZATION_FAIL, payload: error };
};

export const deleteOrganizationSuccess = (payload: any) => {
  return { type: types.DELETE_ORGANIZATION_SUCCESS, payload };
};

export const getOrganizationDataAction = (org_id: number) => {
  return {
    type: types.GET_ORGANIZATION_DATA,
    org_id,
  };
};

export const getOrganizationDataFail = (error: any) => {
  return { type: types.GET_ORGANIZATION_DATA_FAIL, payload: error };
};

export const getOrganizationDataSuccess = (organizationData: any) => {
  return {
    type: types.GET_ORGANIZATION_DATA_SUCCESS,
    payload: organizationData,
  };
};

export const getOrganizationDevicesAction = (org_id: number) => {
  return {
    type: types.GET_ORGANIZATION_DEVICES_DATA,
    org_id,
  };
};

export const getOrganizationDevicesFail = (error: any) => {
  return { type: types.GET_ORGANIZATION_DEVICES_DATA_FAIL, payload: error };
};

export const getOrganizationDevicesSuccess = (organizationData: any) => {
  return {
    type: types.GET_ORGANIZATION_DEVICES_DATA_SUCCESS,
    payload: organizationData,
  };
};

export const cleanCreateOrUpdateResponseAction = () => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE };
};

export const cleanCreateOrUpdateResponseFail = (error: any) => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE_FAIL, payload: error };
};

export const cleanCreateOrUpdateResponseSuccess = () => {
  return { type: types.CLEAN_CREATE_OR_UPDATE_RESPONSE_SUCCESS };
};

export const cleanOrganizationDataAction = () => {
  return { type: types.CLEAN_ORGANIZATION_DATA };
};

export const cleanOrganizationDataFail = (error: any) => {
  return { type: types.CLEAN_ORGANIZATION_DATA_FAIL, payload: error };
};

export const cleanOrganizationDataSucess = () => {
  return { type: types.CLEAN_ORGANIZATION_DATA_SUCCESS };
};

export const getCompaniesDataAction = () => {
  return {
    type: types.GET_COMPANIES_DATA,
  };
};

export const getCompaniesDataFail = (error: any) => {
  return { type: types.GET_COMPANIES_DATA_FAIL, payload: error };
};

export const getCompaniesDataSuccess = (companiesList: any) => {
  return {
    type: types.GET_COMPANIES_DATA_SUCCESS,
    payload: companiesList,
  };
};
