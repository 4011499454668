import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalFormHandlerProps, ReduxType } from "../../../../shared/types";
import {
  findDeviceNetworksAction,
  findDeviceStatesAction,
  findDeviceTypesAction,
  getDeviceUnlinkListDataAction,
} from "../../../../store/devices/actions";
import { getRolesListAction } from "../../../../store/roles/actions";
import { DeviceListComponent } from "../../device";
import { DeviceFormGridComponent } from "../../newDevice/deviceFormGrid";
import { CountrysidesFormGridComponent } from "../../newEstablishment/countrysidesFormGrid";
import { UserFormGridComponent } from "../../newUser/userFormGrid";
import { CompanyFormGridComponent } from "../companyFormGrid";

const ModalFormHandler = (props: ModalFormHandlerProps) => {
  const {
    formValues,
    editionMode,
    name,
    createMode,
    viewMode,
    onChange,
    organizationId,
    selectedFromOrgRows,
    setSelectedFromOrgRows,
  } = props;

  const dispatch = useDispatch();

  const { deviceData, organizationsData, rolesData } = useSelector(
    (state: ReduxType) => {
      return state;
    }
  );

  useEffect(() => {
    switch (name) {
      case "device":
        getAllDeviceData();
        break;
      case "company":
        break;
      case "countrysides":
        break;
      case "user":
        getAllUserData();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (!deviceData.devicesUnlink) {
      getUnlinkDeviceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData.devicesUnlink]);

  const getUnlinkDeviceList = async () => {
    await dispatch(getDeviceUnlinkListDataAction());
  };

  const getAllUserData = async () => {
    await dispatch(getRolesListAction());
  };

  const getAllDeviceData = async () => {
    await dispatch(findDeviceStatesAction());
    await dispatch(findDeviceTypesAction());
    await dispatch(findDeviceNetworksAction());
  };

  switch (name) {
    case "company":
      return (
        <CompanyFormGridComponent
          formValues={formValues}
          onChange={onChange}
          editionMode={editionMode!}
        />
      );
    case "device":
      if (createMode || editionMode || viewMode) {
        return (
          <DeviceFormGridComponent
            fromOrganization
            organizationId={organizationId}
            organizations={organizationsData.organizationsList}
            states={deviceData.states}
            networks={deviceData.networks}
            types={deviceData.types}
            formValues={formValues}
            onChange={onChange}
            editionMode={editionMode!}
          />
        );
      } else {
        return (
          <DeviceListComponent
            fromOrganization
            organizationId={organizationId}
            selectedFromOrgRows={selectedFromOrgRows}
            setSelectedFromOrgRows={setSelectedFromOrgRows}
          />
        );
      }
    case "user":
      return (
        <UserFormGridComponent
          rolesList={rolesData.rolesList || []}
          createMode={createMode!}
          handlePermissionDelete={async () => {}}
          organizations={organizationsData.organizationsList}
          onChange={onChange}
          organizationId={organizationId}
          editionMode={editionMode}
          permissionsList={rolesData.permissionsList}
          formValues={formValues}
        />
      );
    case "countrysides":
      return (
        <CountrysidesFormGridComponent
          organizations={organizationsData.organizationsList}
          organizationId={organizationId}
          editionMode={editionMode!}
          formValues={formValues}
          onChange={onChange}
        />
      );

    default:
      break;
  }
  return <></>;
};

export default ModalFormHandler;
