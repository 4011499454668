import React, { useState } from "react";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";
import { resetUserAction } from "../../store/users/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastComponent } from "../../components/toast";

const ResetPage = (props: any) => {
  const [validated, setValidated] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [userFormReset, setUserFormReset] = useState<any>({
    email: undefined,
    password: undefined,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (
      form.checkValidity() === false ||
      !userFormReset.email ||
      !userFormReset.password
    ) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);
      doResetRequest();
    }
  };

  const doResetRequest = async () => {
    try {
      await dispatch(resetUserAction(userFormReset));
      navigate(Paths.ORGANIZATION);
    } catch (error: any) {
      await setErrorMessage(error.message);
      await setShowToast(true);
    }
  };

  const cleanAndClose = async () => {
    await setShowToast(!showToast);
    await setErrorMessage(undefined);
  };

  return (
    <AuthenticatedRoute withHeader path={Paths.RESET}>
      <ToastComponent
        showToast={showToast}
        toggleShow={() => cleanAndClose()}
        errorType={true}
        bodyContent={errorMessage}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} style={{ marginTop: 24 }}>
          <span className="w-500">Nueva contraseña</span>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="standard-required"
            label="Email"
            type="email"
            variant="standard"
            margin="normal"
            error={
              userFormReset.email === "" ||
              (userFormReset.email === undefined && !validated)
            }
            onChange={(e) =>
              setUserFormReset({
                ...userFormReset,
                email: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="standard-password-input"
            label="Password"
            type="password"
            variant="standard"
            margin="normal"
            error={
              userFormReset.password === "" ||
              (userFormReset.password === undefined && !validated)
            }
            onChange={(e) =>
              setUserFormReset({
                ...userFormReset,
                password: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            className="login-button ligth-green"
            variant="contained"
            onClick={handleSubmit}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </AuthenticatedRoute>
  );
};

export default ResetPage;
