import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomLinkProps, ReduxType } from "../../../shared/types";
import {
  findDeviceNetworksAction,
  findDeviceStatesAction,
  findDeviceTypesAction,
  getDevicesListDataAction,
  getDeviceUnlinkListDataAction,
  linkOrUnlinkOrganizationAction,
} from "../../../store/devices/actions";
import { getOrganizationsListDataAction } from "../../../store/organizations/actions";
import { ToastComponent } from "../../toast";
import { DeviceLinkComponent } from "../deviceLink";
import { GenericGrid } from "../genericGrid";
import { NewDeviceComponent } from "../newDevice";
import { defaultColumns, organizationDeviceModalColumns } from "./Columns";

const DeviceListComponent = (props: any) => {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openLinkOrg, setOpenLinkOrg] = useState(false);
  const [viewMode, setViewMode] = useState<any>(false);
  const [editionMode, setEditionMode] = useState<any>(false);
  const [createMode, setCreateMode] = useState<any>(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const [deviceToEditOrView, setDeviceToEditOrView] = useState();

  const { deviceData, organizationsData } = useSelector((state: ReduxType) => {
    return state;
  });

  const {
    fromOrganization,
    organizationId,
    selectedFromOrgRows,
    setSelectedFromOrgRows,
  } = props;

  React.useEffect(() => {
    getDevicesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (fromOrganization) {
      getUnlinkDeviceList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUnlinkDeviceList = async () => {
    await dispatch(getDeviceUnlinkListDataAction());
  };

  const getDevicesList = async () => {
    await dispatch(getDevicesListDataAction());
  };

  React.useEffect(() => {
    if (!organizationsData.organizationsList) {
      getOrganizationsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrganizationsList = async () => {
    await dispatch(getOrganizationsListDataAction());
  };

  React.useEffect(() => {
    getAllDeviceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllDeviceData = async () => {
    await dispatch(findDeviceStatesAction());
    await dispatch(findDeviceTypesAction());
    await dispatch(findDeviceNetworksAction());
  };

  const linkOrUnlinkOrganization = async (organization_id: number | null) => {
    if (!selectedRows || selectedRows.length === 0) return;

    const data = {
      organization_id: organization_id,
      devices_id: "",
    };

    // eslint-disable-next-line array-callback-return
    selectedRows.map((s) => {
      if (data.devices_id === "") {
        data.devices_id = s;
      } else {
        data.devices_id = `${data.devices_id},${s}`;
      }
    });

    await dispatch(linkOrUnlinkOrganizationAction(data));
    setTimeout(async () => {
      if (organization_id !== null) {
        setOpenLinkOrg(false);
      }
      await getDevicesList();
    }, 500);
    setSelectedRows([]);
  };

  const action = async (action: "VIEW" | "DELETE" | "EDIT", row: any) => {
    switch (action) {
      case "EDIT":
        await setDeviceToEditOrView(row);
        setEditionMode(true);
        setOpen(!open);
        break;
      case "VIEW":
        await setDeviceToEditOrView(row);
        await setViewMode(true);
        await setOpen(!open);
        break;
    }
  };

  const selectedRowsDisabled = !selectedRows || selectedRows.length === 0;

  const headerButtons: CustomLinkProps[] = fromOrganization
    ? []
    : [
        {
          id: "organizations",
          label: "ORGANIZACIONES",
          type: "SELECT",
          disabled: selectedRowsDisabled,
          items: [
            {
              value: 10,
              label: "Asignar organización",
              onClick: () => setOpenLinkOrg(true),
            },
            {
              value: 5,
              label: "Remover organización",
              onClick: () => linkOrUnlinkOrganization(null),
            },
          ],
        },
        {
          id: "newDevice",
          label: "NUEVO DISPOSITIVO",
          type: "SIMPLE",
          className: "login-button ligth-green",
          variant: "contained",
          onClick: () => {
            setEditionMode(true);
            setCreateMode(true);
            setOpen(true);
          },
        },
      ];

  const closeAndResetData = async (device: any) => {
    if (device !== "cancel" && !device.cancelable) {
      setMessage(device);
      setShowToast(true);
    }
    setDeviceToEditOrView(undefined);
    setEditionMode(false);
    setCreateMode(false);
    setOpen(false);
    setOpenLinkOrg(false);
    setViewMode(false);
    setTimeout(async () => {
      await getDevicesList();
    }, 500);
  };

  const cleanAndClose = async () => {
    await setShowToast(false);
    await setMessage(undefined);
    await setEditionMode(false);
    await setCreateMode(false);
  };

  const searchCallback = (val: any, value: any) =>
    val.id === parseInt(value) ||
    (val.name && val.name.toUpperCase().indexOf(value) > -1) ||
    (val.device_types &&
      val.device_types.name &&
      val.device_types.name.toUpperCase().indexOf(value) > -1) ||
    (val.eui_id && val.eui_id.toUpperCase().indexOf(value) > -1) ||
    (val.version && val.version.toUpperCase().indexOf(value) > -1);

  return (
    <>
      {open && (
        <NewDeviceComponent
          open={open}
          organizationId={organizationId}
          deviceToEditOrView={deviceToEditOrView}
          viewMode={viewMode}
          editionMode={editionMode}
          createMode={createMode}
          setEditionMode={setEditionMode}
          handleClose={(device: any) => closeAndResetData(device)}
        />
      )}

      {openLinkOrg && (
        <DeviceLinkComponent
          open={openLinkOrg}
          linkOrUnlinkOrganization={linkOrUnlinkOrganization}
          handleClose={(device: any) => closeAndResetData(device)}
          devices_id={selectedRows}
        />
      )}

      <ToastComponent
        showToast={showToast}
        toggleShow={cleanAndClose}
        errorType={false}
        bodyContent={message}
      />

      <GenericGrid
        selectedRows={fromOrganization ? selectedFromOrgRows : selectedRows}
        setSelectedRows={
          fromOrganization ? setSelectedFromOrgRows : setSelectedRows
        }
        headerButtons={headerButtons}
        columns={
          fromOrganization ? organizationDeviceModalColumns : defaultColumns
        }
        displayData={
          fromOrganization ? deviceData.devicesUnlink : deviceData.devicesList
        }
        searchCallback={searchCallback}
        header={true}
        hasSearchInput={true}
        onActionClick={action}
        actions={{
          view: true,
          delete: false,
          edit: true,
        }}
      />
    </>
  );
};

export default DeviceListComponent;
