import React, { useState } from "react";

import { Badge, FormControl, Grid, InputLabel, MenuItem } from "@mui/material";
import { SliderRangerComponent } from "../../sliderRanger";
import { CustomAccordion } from "../../customAccordion";
import { StyledSelect } from "../../formInputs/SelectComponent/StyledSelect";

const style = {
  backgroundColor: "#FFFFFF",
};

const Zones = [
  {
    id: 1,
    name: "Zona 1",
  },
  {
    id: 2,
    name: "Zona 2",
  },
];

const Species = [
  {
    id: 1,
    name: "Specie 1",
  },
  {
    id: 2,
    name: "Specie 2",
  },
];

const MetricConfigComponent = (props: any) => {
  const [formValues, setFormValues] = useState<any>();

  const { alertMinAndMax, alertValues, short_name, title, width } = props;

  const accordionList = [
    {
      title: "Desagregado por zona",
      alertMinAndMax: alertMinAndMax,
      alertValues: alertValues,
    },
    {
      title: "Desagregado por especie",
      alertMinAndMax: alertMinAndMax,
      alertValues: alertValues,
    },
    {
      title: "Desagregado  zona y especie",
      alertMinAndMax: alertMinAndMax,
      alertValues: alertValues,
    },
  ];

  const onChangeValue = ({ value, name }: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <>
      <Grid
        item
        xs={width <= 768 ? 12 : 10}
        style={{ padding: "1rem", ...style }}
        className="white"
      >
        <div className="mt-2 mb-4 mb-xl-0">
          <div
            className={width <= 768 ? "justify-content-between" : ""}
            style={{ display: "flex" }}
          >
            <div className={""}>{title}</div>
            <div className={"ml-5 "}>
              <Badge
                color="secondary"
                overlap="circular"
                badgeContent={short_name || "Null"}
                style={{
                  backgroundColor: "#222222",
                  maxHeight: 22,
                }}
              ></Badge>
            </div>
          </div>
        </div>
        <div className="mt-5 mb-5 mb-xl-0">
          <SliderRangerComponent
            alertMinAndMax={alertMinAndMax}
            alertValues={alertValues}
          />
        </div>
        {accordionList &&
          accordionList.length > 0 &&
          accordionList.map((ele, index: number) => {
            const xs = ele.title === "Desagregado  zona y especie" ? 5 : 3;
            const ml =
              ele.title === "Desagregado  zona y especie" ? "ml-5" : "";
            const accordionStyle =
              ele.title === "Desagregado  zona y especie" ? "mt-5" : "ml-5";
            return (
              <div key={`ele-${index}`} className="mt-1 mb-1 mb-xl-0">
                <CustomAccordion title={ele.title}>
                  <Grid container alignItems="center" justifyContent="center">
                    {(ele.title === "Desagregado por zona" ||
                      ele.title === "Desagregado  zona y especie") && (
                      <Grid item xs={xs}>
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="zone-select-label">ZONA</InputLabel>
                          <StyledSelect
                            labelId="zone-select-label"
                            id="zone_id"
                            name="zone_id"
                            required
                            onChange={(e) =>
                              onChangeValue({ value: e, name: "zone_id" })
                            }
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                            }}
                          >
                            {Zones &&
                              Zones.length > 0 &&
                              Zones.map((value: any, index: number) => {
                                return (
                                  <MenuItem
                                    value={value.id}
                                    key={`select-city-${index}`}
                                  >
                                    {value.name}
                                  </MenuItem>
                                );
                              })}
                          </StyledSelect>
                        </FormControl>
                      </Grid>
                    )}
                    {(ele.title === "Desagregado por especie" ||
                      ele.title === "Desagregado  zona y especie") && (
                      <Grid item xs={xs} className={ml}>
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="zone-select-label">
                            Especies
                          </InputLabel>
                          <StyledSelect
                            labelId="species-select-label"
                            id="species_id"
                            name="species_id"
                            required
                            onChange={(e) =>
                              onChangeValue({ value: e, name: "species_id" })
                            }
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                            }}
                          >
                            {Species &&
                              Species.length > 0 &&
                              Species.map((value: any, index: number) => {
                                return (
                                  <MenuItem
                                    value={value.id}
                                    key={`select-city-${index}`}
                                  >
                                    {value.name}
                                  </MenuItem>
                                );
                              })}
                          </StyledSelect>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={8} className={accordionStyle}>
                      <SliderRangerComponent
                        alertMinAndMax={ele.alertMinAndMax}
                        alertValues={ele.alertValues}
                      />
                    </Grid>
                  </Grid>
                </CustomAccordion>
              </div>
            );
          })}
      </Grid>
    </>
  );
};

export default MetricConfigComponent;
