export enum Paths {
  LOGIN = "/login",
  LOGOUT = "/logout",
  NOTFOUND = "*",
  ROOT = "/",
  ORGANIZATION = "/organizaciones",
  NEW_ORGANIZATION = "/NuevaOrganizacion",
  DEVICES = "/Dispositivos",
  NEW_DEVICE = "/NuevoDispositivo",
  USERS = "/Usuarios",
  ROLES = "/Roles",
  COSECHAS = "/Cosechas",
  REPORTS = "/Reportes",
  NO_PERMISSIONS = "/NoPermisos",
  METRIC = "/Metric",
  VERIFYACCOUNT = "/VerifyAccount",
  VERIFYRESETACCOUNT = "/VerifyResetAccount",
  RECOVER = "/RecoverAccount",
  RESET = "/reset",
}
