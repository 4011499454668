const columns = [
  {
    id: "username",
    label: "Nombre",
    render: (value: any, record: any) => {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            width: 218,
          }}
        >
          <h3 title={record.fullname || "-"} style={{ margin: 0 }}>
            {record.fullname || "-"}
          </h3>
          <p title={record.email || "-"} style={{ margin: 0 }}>
            {record.email || "-"}
          </p>
        </div>
      );
    },
  },
  {
    id: "datos",
    label: "Datos",
    render: (value: any, record: any) => {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            width: 125,
          }}
        >
          <h3 title={`DNI: ${record.national_id || "-"}`} style={{ margin: 0 }}>
            DNI: {record.national_id || "-"}
          </h3>
          <p title={record.phone || "-"} style={{ margin: 0 }}>
            Tel: {record.phone || "-"}
          </p>
        </div>
      );
    },
  },
  {
    id: "roles",
    label: "Rol",
    width: 100,
    render: (value: any) => {
      return (
        <div>
          <p>{value && value.name}</p>
        </div>
      );
    },
  },
  {
    id: "organizations",
    label: "Organización",
    minWidth: 170,
    render: (value: any) => {
      if (value && value.name) return value.name;
      return <>-</>;
    },
  },
  {
    id: "acciones",
    label: "Acciones",
    minWidth: 90,
  },
];

export { columns };
