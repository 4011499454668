import { Endpoints } from "../../config/endpoints";
import {
  API_URL_AUTH,
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_PROFILE,
  LOCAL_STORAGE_REFRESH_JWT,
} from "../../config/general-config";
import { apiPost } from "../../shared/ApiService";
import { asyncLocalStorage } from "../../shared/helper";
import {
  ApiResponseGeoToID,
  UserVerifyAccountRequest,
} from "../../shared/types";

import { AuthenticateReponse } from "./Type";

const loginWithUsernameAndPassword = async (
  username: string,
  password: string
) => {
  const responseLogin: ApiResponseGeoToID = await genericGeoToIdRequest(
    Endpoints.AUTH.LOGIN,
    { email: username, password: password }
  );

  const access_token = responseLogin.access_token;
  const refresh_token = responseLogin.refresh_token;

  await saveUserProfileToLocalStorage({ username: username });
  await saveTokenToLocalStorage(access_token);
  await saveRefreshTokenToLocalStorage(refresh_token);

  return {
    username,
    access_token,
    refresh_token,
  };
};

const verifyUserData = async (verifyData: UserVerifyAccountRequest) => {
  const responseLogin: ApiResponseGeoToID = await genericGeoToIdRequest(
    Endpoints.AUTH.VERIFY,
    verifyData
  );
  const username = verifyData.email;
  const access_token = responseLogin.access_token;
  const refresh_token = responseLogin.refresh_token;

  if (access_token !== undefined) {
    await saveUserProfileToLocalStorage({ username: username });
    await saveTokenToLocalStorage(access_token);
    await saveRefreshTokenToLocalStorage(refresh_token);
  }
  return {
    username,
    access_token,
    refresh_token,
  };
};

const genericGeoToIdRequest = async (
  method: string,
  body: any,
  token?: string
) => {
  if (token) {
    body.token = token;
  }
  const response = await apiPost({
    url: `${API_URL_AUTH}/${method}`,
    body: body,
    unauthorizedCallback: () => {
      unauthorizedCallback();
    },
  });

  if (response.code === 400 || response.error_description) {
    throw new Error(response.error_description);
  }

  return response;
};

const logout = async () => {
  await asyncLocalStorage.removeItem(LOCAL_STORAGE_JWT);
  await asyncLocalStorage.removeItem(LOCAL_STORAGE_REFRESH_JWT);
  await asyncLocalStorage.removeItem(LOCAL_STORAGE_PROFILE);
  window.location.reload();
};

const getCurrentUser = () => {
  const jsonProfile = localStorage.getItem(LOCAL_STORAGE_PROFILE);
  if (!jsonProfile) {
    return undefined;
  }
  const user: AuthenticateReponse = JSON.parse(
    jsonProfile
  ) as AuthenticateReponse;
  return user.fullname;
};

const getCurrentProfileUser = () => {
  return localStorage.getItem(LOCAL_STORAGE_PROFILE) || undefined;
};

const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_JWT) || undefined;
};

const getRefreshToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_REFRESH_JWT) || undefined;
};

const isLoggedIn = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_JWT);
  const user = localStorage.getItem(LOCAL_STORAGE_PROFILE);

  return !!token && !!user;
};

const saveUserProfileToLocalStorage = async (profile: any) => {
  await asyncLocalStorage.setItem(
    LOCAL_STORAGE_PROFILE,
    JSON.stringify(profile)
  );
};

const saveTokenToLocalStorage = async (token: string) => {
  await asyncLocalStorage.setItem(LOCAL_STORAGE_JWT, token);
};

const saveRefreshTokenToLocalStorage = async (token: string) => {
  await asyncLocalStorage.setItem(LOCAL_STORAGE_REFRESH_JWT, token);
};

const unauthorizedCallback = function () {
  logout();
  window.location.reload();
};

export {
  getToken,
  getRefreshToken,
  getCurrentUser,
  getCurrentProfileUser,
  saveUserProfileToLocalStorage,
  saveRefreshTokenToLocalStorage,
  isLoggedIn,
  logout,
  loginWithUsernameAndPassword,
  unauthorizedCallback,
  verifyUserData,
};
